
import {createContext,useState} from "react";
export const AuthContext = createContext(null)
export default function  Context ({children}) {
  const [role,setRole] = useState(null);
  const [permissions,setPermissions] = useState([]);
  return (
      <AuthContext.Provider value={{role,setRole,permissions,setPermissions}}>
          {children}
        </AuthContext.Provider> 
  )
}