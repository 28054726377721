import React, { useEffect, useState, useRef } from "react";
import axios from "../../Utils/Axios";
import { Link, useHistory } from "react-router-dom";
import "./Templates.css";
import $ from "jquery";
import Modal from "react-modal";
import { Avatar } from "@mui/material";
const toggle = () => {
  $("body").toggleClass("sidebar-toggled");
  $(".sidebar").toggleClass("toggled");
  if ($(".sidebar").hasClass("toggled")) {
    $(".sidebar .collapse").collapse("hide");
  }
};

function Header({ rand }) {
  const history = useHistory();
  const [user, setUser] = useState({});
  const [notification, setNotification] = useState([]);
  const [keyword, setKeyword] = useState();
  const [isOpen2, setIsOpen2] = useState(false);
  const [itemComments, setItemComments] = useState({});
  const [comment, setComment] = useState("");
  const [itemId, setItemId] = useState(0);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    getNotificationData();
  }, [rand]);
  useEffect(() => {
    getUserData();
  }, []);

  const getItemMessages = (order_item_id) => {
    setIsOpen2(true);
    setItemId(order_item_id);

    axios(`Orders/get_order_item_comments?order_item_id=${order_item_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === false) {
          setItemComments(res.data.data);
          scrollToBottom();
        } else {
          setItemComments(res.data.data);
          scrollToBottom();
        }
      })
      .catch((err) => console.log(err));
  };

  const addOrderComment = (orderItemId, comment) => {
    let formdata = new FormData();
    formdata.append("order_item_id", orderItemId);
    formdata.append("comment", comment);
    axios(`Orders/add_order_item_comment`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === false) {
          getItemMessages(orderItemId);
          setComment("");
        }
      })
      .catch((err) => console.log(err));
  };
  const getUserData = () => {
    axios(`Users/get_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setUser(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const changeStoreLocation = (value) => {
    axios(`Users/change_store_location?location_id=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  const getNotificationData = () => {
    axios(`Users/get_notification`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
          setNotification([]);
        } else {
          setNotification(res.data.data);
          // const audio = new Audio(
          //   "https://images.shopkees.com/uploads/img/audio/notify.mp3"
          // );
          // audio.play();
        }
      })
      .catch((err) => console.log(err));
  };

  const globelSearch = (e) => {
    if (e === "Enter") {
      window.location.replace(`/SearchOrders?keyword=${keyword}`);
    }
  };

  const customStyles2 = {
    content: {
      //background: "linear-gradient(180deg,#00475d,#00a6d9)",
      top: "20%",
      left: "25%",
      width: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "0%",
      transform: "fade in",
    },
  };

  const logOut = () => {
    localStorage.removeItem("data");
  };
  return (
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top  toggled ">
      <button
        onClick={toggle}
        id="sidebarToggleTop"
        class="btn btn-link   rounded-circle mr-3"
      >
        <i class="fa fa-bars"></i>
      </button>
      <Modal
        isOpen={isOpen2}
        shouldCloseOnOverlayClick={true}
        onRequestClose={(e) => setIsOpen2(false)}
        style={customStyles2}
        contentLabel="Example Modal"
      >
        <div className="container">
          <div class="row order-noti">
            <h6>Order ID : {itemComments.order_no}</h6>
            <h5>Order Name : {itemComments.product_name}</h5>
            <h6> Customer : {itemComments.customer_name}</h6>
            <h6> Email : {itemComments.email}</h6>
            <h6> Mobile : {itemComments.mobile_no}</h6>
            <h6> Address : {itemComments.address}</h6>
          </div>
          <div class="row">
            <div
              style={{ overflow: "scroll", height: "400px", width: "100%" }}
              id="msg_container"
            >
              {itemComments &&
                itemComments.comments &&
                itemComments.comments.map((row, i) => {
                  return row.created_by == itemComments.user_id ? (
                    <div class="row m-0" style={{ justifyContent: "end" }}>
                      <div class="to-msg">
                        <div class="send-details">
                          <p class="to-time">{row.created_at}</p>
                          <h6 class="to-name">{row.name}</h6>
                        </div>

                        <div class="to-chat">{row.comment}</div>
                      </div>
                    </div>
                  ) : (
                    <div class="row m-0">
                      <div class="from-msg">
                        <div class="rec-details">
                          <h6 class="rec-name">{row.name}</h6>
                          <p class="msg-time">{row.created_at}</p>
                        </div>
                        <div class="rec-chat">{row.comment}</div>
                      </div>{" "}
                    </div>
                  );
                })}
            </div>
          </div>
          <div ref={messagesEndRef} class="row msg_send">
            <div class="col-md-10">
              <input
                class="form-control"
                name="message-to-send"
                onChange={(e) => setComment(e.target.value)}
                id="message-to-send"
                placeholder="Type your message"
                type="text"
                value={comment}
              ></input>
            </div>
            <div class="col-md-2">
              <button
                class="btn btn-sm  btn-info"
                onClick={() => addOrderComment(itemId, comment)}
                id="msg_send_order"
              >
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div class="main-header-left  p-relative col-md-6 col-8">
        {" "}
        <input
          className="form-control"
          placeholder="Search for Orders..."
          type="text"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && globelSearch(e.key)}
        ></input>{" "}
        <button
          onClick={() => globelSearch("Enter")}
          class="btn input-search-btn"
        >
          <i class="fas fa-search d-none d-md-block"></i>
        </button>{" "}
      </div>

      <div class="col-md-2 col-2">
        {" "}
        <select
          className="form-control"
          onChange={(e) => changeStoreLocation(e.target.value)}
        >
          <option
            selected={user.store_location == 1 ? "selected" : ""}
            value="1"
          >
            Bur Dubai
          </option>
          <option
            selected={user.store_location == 2 ? "selected" : ""}
            value="2"
          >
            Deira
          </option>
        </select>
      </div>

      <div className="nav-item dropdown no-arrow mx-1">
        <Link
          class="nav-link dropdown-toggle"
          id="userDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="mr-2 d-none d-lg-inline text-gray-600 small"></span>
        </Link>
      </div>

      <ul class="navbar-nav w-100 justify-content-end ">
        <li class="nav-item dropdown no-arrow mx-1">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="messagesDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-bell fa-fw"></i>

            <span class="badge badge-danger badge-counter">
              {notification.length}
            </span>
          </a>

          <div
            class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="messagesDropdown"
          >
            <h6 class="dropdown-header">Message Center</h6>
            {notification.slice(0, 10).map((value, index) => {
              return (
                // <Link to={{ pathname: `/orderItems`, isDate: 1, keyword:  value.order_id}} >
                <a
                  class="dropdown-item d-flex align-items-center"
                  onClick={() => getItemMessages(value.order_item_id)}
                >
                  <div class="dropdown-list-image mr-3">
                    <Avatar alt={value.name} src="/broken-image.jpg"></Avatar>
                    <div
                      className={
                        value.is_clicked == 0
                          ? "status-indicator bg-success "
                          : "status-indicator"
                      }
                    ></div>
                  </div>
                  <div
                    className={value.is_clicked == 0 ? "font-weight-bold" : ""}
                  >
                    <div class="text-truncate">{value.comment}</div>
                    <div class="small text-gray-500">
                      {value.name} · {value.created_at}
                    </div>
                  </div>
                </a>
                // </Link>
              );
            })}
            <Link
              class="dropdown-item text-center   text-gray-500"
              to="/notification"
            >
              Read More Messages
            </Link>
          </div>
        </li>

        <div class="topbar-divider d-none d-sm-block"></div>

        <li class="nav-item dropdown no-arrow">
          <div
            class="nav-link dropdown-toggle"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="mr-2 d-none d-lg-inline text-gray-600 small">
              Shopkees.com
            </span>
            <img
              class="img-profile rounded-circle"
              alt=""
              src="https://source.unsplash.com/QAB-WJcbgJk/60x60"
            />
          </div>

          <div
            class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            <Link class="dropdown-item" to="/profile">
              <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
              Profile
            </Link>
            <div class="dropdown-divider"></div>
            <Link class="dropdown-item" onClick={logOut} to="/login">
              <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
