import React, { useState, useEffect, useRef } from 'react'
import Box from "@mui/material/Box";
import axios from "../../Utils/Axios";
import Axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import { SITE_URL } from "../../Constants/constants";

function ScanShipment() {
    const textInput = useRef(null);
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [scanedOrders, setScanedOrders] = useState([]);
    const [AWBNumber, setAWBNumber] = useState('');
    useEffect(() => {
        if (AWBNumber != '' && AWBNumber.length > 5) {
            checkOrderAndUpdate();
        }
    }, [AWBNumber]);
    useEffect(() => {
        getScannedOrders();
    }, []);
    const getScannedOrders = () => {

        setLoading(true);
        axios(
            `Orders/get_scanned_orders`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "x-api-key": JSON.parse(localStorage.getItem("data")),
                },
            }
        )
            .then((res) => {
                if (res.data.error) {
                    setLoading(false);
                    alert.show(res.data.message);
                } else {

                    setLoading(false);
                    setScanedOrders(res.data.data);

                }
            })
            .catch((err) => console.log(err));
    }
    const checkOrderAndUpdate = () => {
        setLoading(true);
        Axios(
            `${SITE_URL}shpcpanel/Orders/check_and_update?awb_number=${AWBNumber}&delay=1`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "x-api-key": JSON.parse(localStorage.getItem("data")),
                },
            }
        )
            .then((res) => {
                if (!res.data.error) {
                    setAWBNumber('');
                    setLoading(false);
                    alert.success('success');
                    textInput.current.focus();
                    getScannedOrders();

                } else {
                    textInput.current.blur();
                    setLoading(false);
                    alert.error(res.data.message);
                }
            })
            .catch((err) => console.log(err));
    }

    const shipAll = () => {
        setLoading(true);
        Axios(
            `${SITE_URL}shpcpanel/Orders/ship_all_scanned_orders?&delay=1`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "x-api-key": JSON.parse(localStorage.getItem("data")),
                },
            }
        )
            .then((res) => {
                if (!res.data.error) {
                    setLoading(false);
                    alert.success('success');
                    getScannedOrders();
                } else {
                    setLoading(false);
                    alert.error(res.data.message);
                }
            })
            .catch((err) => console.log(err));
    }

    return (

        <div>
            <LoadingOverlay
                active={loading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: "75px",
                        "& svg circle": {
                            stroke: "rgba(0, 0, 0, 0.8)",
                        },
                    }),
                }}
                spinner
                text="Loading ..."
            >
                <div className='col-md-12  scan-barcode'>

                    <input type="text" ref={textInput} value={AWBNumber} onChange={(e) => setAWBNumber(e.target.value)} placeholder='Scan Barcode' autoFocus ></input>

                </div>
                <div className='col-md-12'>

                    <table className="table table-bordered  ">

                        <thead>
                            <th>NO</th>
                            <th>ORDER NO</th>
                            <th>AWB</th>
                            <th>DATE</th>
                        </thead>
                        <tbody>
                            {scanedOrders.map((value, index) => {
                                return (
                                    <tr>
                                        <td>{index+1}</td>
                                        <td>{value.order_id}</td>
                                        <td>{value.awb_number}</td>
                                        <td>{value.created_at}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    <button onClick={()=>shipAll()} className="btn btn-info btn-sm" >Ship All</button>

                </div>
            </LoadingOverlay>
        </div>



    )
}

export default ScanShipment
