import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import PendingOrderItems from "./OrderItemTabs/PendingOrderItems";
import OrderedOrderItems from "./OrderItemTabs/OrderedOrderItems";
import InstockOrderItems from "./OrderItemTabs/InstockOrderItems";
import PriorityOrderItems from "./OrderItemTabs/PriorityOrderItems";
import AllOrderItems from "./OrderItemTabs/AllOrderItems";
import PendingWithMessage from "./OrderItemTabs/PendingWithMessage";
import PendingWithTimeExtend from "./OrderItemTabs/PendingWithTimeExtend";
import AllPendingOrderItems from "./OrderItemTabs/AllPendingOrderItems";
import IsAllowed from "../../Utils/IsAllowed";

import "react-tabs/style/react-tabs.css";
import "./Orders.css";

function OrderItems(props) {
  const [orderedCount, setOrderedCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [allPendingCount, setAllPendingCount] = useState(0);
  const [inStockCount, setInStockCount] = useState(0);
  const [priorityCount, setPriorityCount] = useState(0);
  const [allCount, setAllCount] = useState(0);
  const [timeExtendedCount, setTimeExtendedCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);

  const [tabIndex, setTabIndex] = useState(
    props && props.location && props.location.tab ? props.location.tab : 0
  );
  const [keyword, setKeyword] = useState(
    props && props.location && props.location.keyword
      ? props.location.keyword
      : ""
  );
  const [isDate, setIsDate] = useState(
    props && props.location && props.location.isDate ? props.location.isDate : 0
  );

  return (
    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
      <TabList>
        <Tab>
          AllPending
          <span class="badge badge-danger ml-2 badge-counter">
            {allPendingCount}{" "}
          </span>
        </Tab>
        <Tab>
          Pending
          <span class="badge badge-danger ml-2 badge-counter">
            {pendingCount}{" "}
          </span>
        </Tab>
        <Tab>
          With Message - Pending
          <span class="badge badge-dark ml-2 badge-counter">
            {messageCount}
          </span>
        </Tab>
        <Tab>
          Time Extended - Pending
          <span class="badge badge-secondary ml-2 badge-counter">
            {timeExtendedCount}
          </span>
        </Tab>
        <Tab>
          Priority - Pending
          <span class="badge badge-info ml-2 badge-counter">
            {priorityCount}
          </span>
        </Tab>
        <Tab>
          Ordered
          <span class="badge badge-warning ml-2 badge-counter">
            {orderedCount}{" "}
          </span>
        </Tab>
        <Tab>
          In Stock
          <span class="badge badge-success ml-2 badge-counter">
            {inStockCount}
          </span>
        </Tab>
        <Tab>
          All
          <span class="badge badge-primary ml-2 badge-counter">{allCount}</span>
        </Tab>
      </TabList>

      <TabPanel>
        <AllPendingOrderItems
          setAllPendingCount={setAllPendingCount}
          isDate={isDate}
          keyword={keyword}
        ></AllPendingOrderItems>
      </TabPanel>
      <TabPanel>
        <PendingOrderItems
          setPendingCount={setPendingCount}
          isDate={isDate}
          keyword={keyword}
        ></PendingOrderItems>
      </TabPanel>

      <TabPanel>
        <PendingWithMessage
          setMessageCount={setMessageCount}
          isDate={isDate}
          keyword={keyword}
        ></PendingWithMessage>
      </TabPanel>
      <TabPanel>
        <PendingWithTimeExtend
          setTimeExtendedCount={setTimeExtendedCount}
          isDate={isDate}
          keyword={keyword}
        ></PendingWithTimeExtend>
      </TabPanel>
      <TabPanel>
        <PriorityOrderItems
          setPriorityCount={setPriorityCount}
          isDate={isDate}
          keyword={keyword}
        ></PriorityOrderItems>
      </TabPanel>
      <TabPanel>
        <OrderedOrderItems
          setOrderedCount={setOrderedCount}
          isDate={isDate}
          keyword={keyword}
        ></OrderedOrderItems>
      </TabPanel>
      <TabPanel>
        <InstockOrderItems
          setInStockCount={setInStockCount}
          isDate={isDate}
          keyword={keyword}
        ></InstockOrderItems>
      </TabPanel>
      <TabPanel>
        <AllOrderItems
          setAllCount={setAllCount}
          isDate={isDate}
          keyword={keyword}
        ></AllOrderItems>
      </TabPanel>
    </Tabs>
  );
}

export default OrderItems;
