import React, { useEffect, useState } from "react";

import LoadingOverlay from "react-loading-overlay";
//import DataTable from "react-data-table-component";
import axios from "../../../Utils/Axios";
//import ReactPaginate from "react-paginate";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { useHistory } from "react-router-dom";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
//import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
//import { IMAGE_URL } from "../../Constants/constants";

import fileDownload from "js-file-download";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
//import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ItemDetails from "../ItemDetails";

import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: "0px",
  },
  table: {
    minWidth: 700,
  },
  visuallyHidden: {
    border: 3,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  PresetDateRangePicker_panel: {
    padding: "0 22px 11px 22px",
  },

  PresetDateRangePicker_button: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid #138496 `,
    color: "#138496",
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
  },

  PresetDateRangePicker_button__selected: {
    color: "white",
    background: "blue",
  },
}));

// const StyledBadge = withStyles((theme) => ({
//   badge: {
//     right: -3,
//     top: 13,
//     border: `2px solid ${theme.palette.background.paper}`,
//     padding: "0 4px",
//   },
// }))(Badge);

function OrderedOrderItems(props) {
  const classes = useStyles();
  const history = useHistory();
  const today = moment();
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState();
  const [per_page, setPer_page] = useState(10);
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [stores, setStores] = useState([]);

  const [selectBrand, setSelectBrand] = useState(-1);
  const [selectCategory, setSelectCategory] = useState(0);
  const [selectVendor, setSelectVendor] = useState(0);
  const [selectSeller, setSelectSeller] = useState(1);
  const [selectStore, setSelectStore] = useState("");
  const [isDate, setIsDate] = useState(
    props && props.location && props.location.isDate ? props.location.isDate : 0
  );
  const [startDate, setStartDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [endDate, setEndDate] = useState(null);
  //const [search, setSearch] = useState("");
  // const [search, setSearch] = useState(
  //   props && props.location && props.location.keyword
  //     ? props.location.keyword
  //     : ""
  // );
  const [search, setSearch] = useState(
    props.keyword && props.keyword ? props.keyword : ""
  );
  const [itemStatus, setItemStatus] = useState(-1);

  const [page, setPage] = useState(1);
  const alert = useAlert();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    getBrands();
    getCategories();
    getSellers();
    getStores();
    // getVendors();
  }, []);
  useEffect(() => {
    orders_list();
  }, [
    page,
    search,
    per_page,
    endDate,
    startDate,
    itemStatus,
    selectBrand,
    selectVendor,
    selectCategory,
    selectSeller,
    selectStore,
  ]);
  // const viewDetails = (id) => {
  //   history.push({
  //     pathname: "/order-details",
  //     state: { id: id },
  //   });
  // };

  //const today = moment();
  const yesterday = moment().add(-1, "day");
  const presets = [
    {
      text: "Today",
      start: today,
      end: today,
    },
    {
      text: "Yesterday",
      start: yesterday,
      end: yesterday,
    },
    {
      text: "This Week",
      end: today,
      start: moment().add(-1, "week"),
    },
    {
      text: "This Month",
      end: today,
      start: moment().add(-1, "month"),
    },
    {
      text: "This Year",
      end: today,
      start: moment().add(-1, "year"),
    },
  ];

  const getBrands = () => {
    axios(`Dashboard/get_brands`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (res.data.error) {
          } else {
            setBrands(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCategories = () => {
    axios(`Dashboard/get_categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (res.data.error) {
          } else {
            setCategories(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  // const getVendors = () => {
  //   axios(`Vendor/get_vendors`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //       "x-api-key": JSON.parse(localStorage.getItem("data")),
  //     },
  //   })
  //     .then((res) => {
  //       if (res.data.error) {
  //       } else {
  //         setVendors(res.data.data);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  const getSellers = () => {
    axios(`Dashboard/getSellers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.status === 200) {
          const { error, data, message } = res.data;
          if (error) {
            setSellers([]);
          } else {
            setSellers(data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getStores = () => {
    axios(`Dashboard/get_stores`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setStores(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };
  const orders_list = () => {
    setLoading(true);
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    axios(
      `Orders/order_items_list?order_status=pending&page=${page}&per_page=${per_page}&search=${search}&item_status=1&start_date=${start}&end_date=${end}&brand_id=${selectBrand}&category_id=${selectCategory}&vendor_id=${selectVendor}&seller_id=${selectSeller}&store_id=${selectStore}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
            setOrders(res.data.data);
            setTotal(res.data.total);
            props.setOrderedCount(res.data.total_count);
          } else {
            //console.log(res.data.data);
            setLoading(false);
            setOrders(res.data.data);
            setTotal(res.data.total);
            props.setOrderedCount(res.data.total_count);

            //setPer_page(10);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const export_orders_list = async () => {
    setLoading(true);
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    axios(
      `Orders/export_order_items_list?order_status=pending&search=${search}&item_status=1&start_date=${start}&end_date=${end}&brand_id=${selectBrand}&category_id=${selectCategory}&vendor_id=${selectVendor}&seller_id=${selectSeller}&store_id=${selectStore}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        setLoading(false);
        fileDownload(res.data, "orders.csv");
      })
      .catch((err) => console.log(err));
  };

  const onDatesChange = (start, end) => {
    setEndDate(end);
    setStartDate(start);
  };

  function renderDatePresets() {
    return (
      <div className={classes.PresetDateRangePicker_panel}>
        {presets.map(({ text, start, end }) => {
          //const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);
          return (
            <button
              key={text}
              className={classes.PresetDateRangePicker_button}
              type="button"
              onClick={() => onDatesChange(start, end)}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <div class="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">Order Items</h1>

        <div class="card shadow mb-4">
          <div class="card-body">
            <div className="row row-order">
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search...."
                />
              </div>
              <div className="col-md-3">
                <select
                  value={selectSeller}
                  className="form-control form-control-lg"
                  onChange={(e) => setSelectSeller(e.target.value)}
                >
                  <option value="0">Select Seller</option>
                  {sellers.map((value) => {
                    return (
                      <option value={value.id} key={value.id}>
                        {value.display_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-4">
                <DateRangePicker
                  presets={presets}
                  startDate={startDate}
                  startDateId="s_id"
                  renderCalendarInfo={renderDatePresets}
                  endDate={endDate}
                  endDateId="e_id"
                  showClearDates={true}
                  isOutsideRange={(day) => moment().diff(day) < 0}
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="YYYY-MM-DD"
                />
              </div>
              <div className="col-md-2">
                <input
                  type="button"
                  onClick={export_orders_list}
                  className="btn btn-success"
                  value="export"
                />
              </div>
              {/* <div className="col-md-3">
                <select
                  className="form-control form-control-lg"
                  onChange={(e) => setItemStatus(e.target.value)}
                >
                  <option value="-1" selected>
                    Select All
                  </option>
                  <option value="0">Pending</option>
                  <option value="1">Ordered</option>
                  <option value="2">In stock</option>
                  <option value="3">Priority</option>
                </select>
              </div> */}
              <div className="col-md-3">
                <select
                  className="form-control form-control-lg"
                  onChange={(e) => setSelectBrand(e.target.value)}
                >
                  <option value="-1">Select Brands</option>
                  {brands.map((value) => {
                    return (
                      <option value={value.id} key={value.id}>
                        {value.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-control form-control-lg"
                  onChange={(e) => setSelectStore(e.target.value)}
                >
                  <option value="">Select Store</option>
                  {stores.map((value) => {
                    return (
                      <option value={value.currency} key={value.id}>
                        {value.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* <div className="col-md-4">
                <select
                  className="form-control"
                  onChange={(e) => setSelectVendor(e.target.value)}
                >
                  <option value="0">Select Vendor</option>
                  {vendors.map((value) => {
                    return (
                      <option value={value.id} key={value.id}>
                        {value.name}
                      </option>
                    );
                  })}
                </select>
              </div> */}
              <div className="col-md-3">
                <select
                  className="form-control form-control-lg"
                  onChange={(e) => setSelectCategory(e.target.value)}
                >
                  <option value="0">Select Category</option>
                  {categories.map((value) => {
                    return (
                      <option value={value.id} key={value.id}>
                        {value.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div style={{ marginTop: 30 }}>
              <LoadingOverlay
                active={loading}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 0.9)",
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: "75px",
                    "& svg circle": {
                      stroke: "rgba(0, 0, 0, 0.8)",
                    },
                  }),
                }}
                spinner
                text="..."
              >
                {orders.map((value, index) => {
                  return (
                    <Accordion
                      expanded={expanded === "panel" + index}
                      onChange={handleChange("panel" + index)}
                    >
                      <AccordionSummary
                        //expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Paper className={classes.paper}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            flexWrap="wrap"
                            m={1}
                            //marginBottom="40px"
                            p={1}
                            bgcolor="Background.paper"
                          >
                            <Box p={1}>
                              {/* <Link to="/PostJobDetails"> */}
                              <Typography
                                variant="subtitle1"
                                maxWidth="350px"
                                minWidth="350px"
                                gutterBottom
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    value.product_name
                                  );
                                  alert.info("product name copied");
                                }}
                              >
                                {value.product_name}
                              </Typography>
                              <Typography
                                variant="h8"
                                color="red"
                                gutterBottom
                              >
                                {value.seller}
                              </Typography>
                              <br></br>
                              <Typography
                                variant="overline"
                                color="primary"
                                gutterBottom
                                onClick={() => {
                                  navigator.clipboard.writeText(value.sku);
                                  alert.info("product SKU copied");
                                }}
                              >
                                {value.sku}
                              </Typography>
                            </Box>
                            <Box p={1} color="primary" display="grid">
                              <Typography
                                variant="h6"
                                color="primary"
                                gutterBottom
                              >
                                {value.qty}
                              </Typography>
                            </Box>
                            <Box p={1} display="grid">
                              <Typography
                                variant="h6"
                                color="primary"
                                gutterBottom
                              >
                                {value.price}
                              </Typography>
                            </Box>
                            <Box p={1} display="grid">
                              <Typography
                                variant="h6"
                                color="green"
                                gutterBottom
                              >
                                {value.price_final}
                              </Typography>
                            </Box>
                            <Box p={1} display="grid"></Box>

                            <Box p={1} display="grid">
                              {value.qty -
                                (Number(value.ordered_count) +
                                  Number(value.at_hub_count)) ==
                              0 ? (
                                ""
                              ) : (
                                <Typography
                                  variant="subtitle2"
                                  color="red"
                                  gutterBottom
                                >
                                  Pending
                                  <span class="badge badge-danger badge-counter">
                                    {value.qty -
                                      (Number(value.ordered_count) +
                                        Number(value.at_hub_count))}
                                  </span>
                                </Typography>
                              )}
                              {value.ordered_count == 0 ? (
                                ""
                              ) : (
                                <>
                                <Typography
                                  variant="subtitle2"
                                  color="orange"
                                  gutterBottom
                                >
                                  Ordered
                                  <span class="badge badge-warning badge-counter">
                                    {value.ordered_count}
                                  </span>
                                 
                                </Typography>
                                <Typography
                                variant="subtitle2"
                                color="black"
                                gutterBottom
                              >
                                
                               
                                  {value.updated_by}
                                
                                
                              </Typography>
                              </>
                              )}
                              {value.at_hub_count == 0 ? (
                                ""
                              ) : (
                                <Typography
                                  variant="subtitle2"
                                  color="green"
                                  gutterBottom
                                >
                                  In Stock
                                  <span class="badge badge-success badge-counter">
                                    {value.at_hub_count}
                                  </span>
                                </Typography>
                              )}
                            </Box>
                            <Box p={1} display="grid">
                              {value.commented_flag && (
                                <i
                                  class="fa fa-envelope"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </Box>
                            <Box p={1} display="grid">
                              <Typography
                                variant="h6"
                                color="secondary"
                                gutterBottom
                                align="center"
                              >
                                Processed Date
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                color="primary"
                                gutterBottom
                                align="center"
                              >
                                {value.date_ordered}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                color="primary"
                                gutterBottom
                                align="center"
                              >
                                {value.time_ordered}
                              </Typography>
                            </Box>
                          </Box>
                        </Paper>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* <Typography>{value.order_data}</Typography> */}

                        <ItemDetails
                          itemDetail={value.order_data}
                          orders_list={orders_list}
                          vendors={vendors}
                        ></ItemDetails>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </LoadingOverlay>

              <div>
                <Box p={2} display="flex" justifyContent="center">
                  <Stack spacing={2}>
                    <Pagination
                      color="secondary"
                      count={total}
                      page={page}
                      onChange={(event, value) => {
                        setPage(value);
                      }}
                    />
                  </Stack>
                  <Stack spacing={2}>
                    <Select
                      value={per_page}
                      onChange={(e) => setPer_page(e.target.value)}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </Stack>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderedOrderItems;
