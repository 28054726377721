import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "../../Utils/Axios";

import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { useHistory } from "react-router-dom";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import fileDownload from "js-file-download";

//import { IMAGE_URL } from "../../Constants/constants";

import ReturnDetails from "./ReturnDetails";

import IsAllowed from "../../Utils/IsAllowed";
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  PresetDateRangePicker_panel: {
    padding: "0 22px 11px 22px",
  },

  PresetDateRangePicker_button: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid #138496 `,
    color: "#138496",
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
  },

  PresetDateRangePicker_button__selected: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid #138496 `,
    color: "#138496",
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
    color: "white",
    background: "#138496",
  },
}));

function PendingReturns(props) {
  const classes = useStyles();
  const history = useHistory();
  const today = moment();
  const [returns, setReturns] = useState([]);


  const [total, setTotal] = useState();

  const [per_page, setPer_page] = useState(10);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState("");
  const [type, setType] = useState(0);
  const [page, setPage] = useState(1);

  const alert = useAlert();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    return_list();
  }, [
    page,
    search,
    per_page,
    endDate,
    startDate,
    type
  ]);



  //const today = moment();
  const yesterday = moment().add(-1, "day");
  const presets = [
    {
      text: "Today",
      start: today,
      end: today,
    },
    {
      text: "Yesterday",
      start: yesterday,
      end: yesterday,
    },
    {
      text: "This Week",
      start: moment().add(-1, "week"),
      end: today,
    },
    {
      text: "This Month",
      start: moment().add(-1, "month"),
      end: today,
    },
    {
      text: "This Year",
      start: moment().add(-1, "year"),
      end: today,
    },
  ];

  const onDatesChange = (start, end) => {
    setEndDate(end);
    setStartDate(start);
  };



  function isSameDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    return (
      a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
    );
  }

  function renderDatePresets() {
    return (
      <div className={classes.PresetDateRangePicker_panel}>
        {presets.map(({ text, start, end }) => {
          const isSelected =
            isSameDay(start, startDate) && isSameDay(end, endDate);
          return (
            <button
              key={text}
              className={
                isSelected
                  ? classes.PresetDateRangePicker_button__selected
                  : classes.PresetDateRangePicker_button
              }
              type="button"
              onClick={() => onDatesChange(start, end)}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  }

  const handleTracking = (index, dataType, value) => {
    const newState = returns.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setReturns(newState);
  };


  const updateReturnRequest = (returnData) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("id", returnData.id);
    formData.append("reason", returnData.reason);
    formData.append("type", returnData.type);
    formData.append("status_id", returnData.status_id);
    axios(`Orders/return_request_update`, {
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          alert.success(res.data.message);
          return_list();
        }
      })
      .catch((err) => console.log(err));
  };
  const return_list = () => {
    setLoading(true);
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    axios(
      `Orders/return_request_list?order_status=pending&page=${page}&per_page=${per_page}&search=${search}&return_type=${type}&start_date=${start}&end_date=${end}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
            setReturns(res.data.data);
            setTotal(res.data.total);
            props.setPendingCount(res.data.total_count);
          } else {
            //console.log(res.data.data);
            setLoading(false);
            setReturns(res.data.data);
            setTotal(res.data.total);
            props.setPendingCount(res.data.total_count);
            //setPer_page(10);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const export_data = () => {
    setLoading(true);
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    axios(
      `Orders/export_return_list?order_status=pending&search=${search}&return_type=${type}&start_date=${start}&end_date=${end}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        setLoading(false);
        fileDownload(res.data, "returns.csv");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">Pending Orders</h1>

        <div class="card shadow mb-4">
          <div class="card-body">
           
              <div className="row return-row">
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control  form-control-lg "
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search...."
                  />
                </div>
                <div className="col-md-3">
                  <select
                    className="form-control form-control-lg"
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="0">Select One</option>
                    <option value="1">Cancelled/Refund</option>
                    <option value="2">Item Replace</option>
                    <option value="3">For Warranty</option>
                  </select>
                </div>


                <div className="col-md-5">
                  <DateRangePicker
                    presets={presets}
                    renderCalendarInfo={renderDatePresets}
                    showClearDates={true}
                    startDate={startDate}
                    startDateId="s_id"
                    endDate={endDate}
                    endDateId="e_id"
                    isOutsideRange={(day) => moment().diff(day) < 0}
                    onDatesChange={({ startDate, endDate }) => {
                      setStartDate(startDate);
                      setEndDate(endDate);
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(e) => setFocusedInput(e)}
                    displayFormat="YYYY-MM-DD"
                  />
                </div>
                <div className="col-md-1">
                  <input
                    type="button"
                    onClick={export_data}
                    className="btn btn-success"
                    value="Export"
                  />
                </div>
              </div>
            


            <div style={{ marginTop: 50 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="..."
                >
                  {returns.map((value, index) => {
                    return (
                      <Accordion
                        expanded={expanded === "panel" + index}
                        onChange={handleChange("panel" + index)}
                      >
                        <AccordionSummary
                          //expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Paper className={classes.paper}>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              flexWrap="wrap"
                              m={1}
                              //marginBottom="40px"
                              p={1}
                              bgcolor="Background.paper"
                            >
                              {/* <Box p={1}>
          <Checkbox />
        </Box> */}
                              <Box p={1} maxWidth="300px" minWidth="300px">
                                {/* <Link to="/PostJobDetails"> */}
                                <Typography
                                  variant="h8"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      value.product
                                    );
                                    alert.info(" product name  copied");
                                  }}
                                  gutterBottom
                                >
                                  {value.product}
                                </Typography>
                                <br></br>
                                <Typography
                                  variant="overline"
                                  color="primary"
                                  gutterBottom
                                >
                                  {value.serial_no}
                                </Typography>
                              </Box>
                              <Box p={1} color="primary" display="grid">
                                <Typography
                                  variant="subtitle2"
                                  color="primary"
                                  gutterBottom
                                >
                                  {value.qty}
                                </Typography>
                              </Box>

                              <Box p={1} display="grid">
                                <Typography
                                  variant="subtitle1"
                                  color="tomato"
                                  gutterBottom
                                >
                                  {value.order}
                                </Typography>

                              </Box>
                              <Box p={1} display="grid">
                                <Typography
                                  variant="subtitle1"
                                  color="green"
                                  gutterBottom
                                >
                                  {value.invoice_no}
                                </Typography>

                              </Box>
                              <Box p={1} display="grid" maxWidth="250px" minWidth="200px">
                                <Typography
                                  variant="overline"
                                  color="royalblue"
                                  gutterBottom
                                >
                                  {value.reason}
                                </Typography>

                              </Box>
                              <Box p={1} display="grid">
                                <Typography
                                  variant="subtitle2"
                                  color="brown"
                                  gutterBottom
                                >
                                  {value.created_at}
                                </Typography>
                              </Box>


                            </Box>
                          </Paper>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <Box>
                              <label>Return Type</label>
                              <select value={value.type} onChange={(e) =>
                                handleTracking(
                                  index,
                                  "type",
                                  e.target.value
                                )
                              } className="form-control form-control-lg">
                                <option value="0">
                                  Select One
                                </option>
                                <option value="1">
                                  Cancelled/Refund
                                </option>
                                <option value="2">
                                  Item Replace
                                </option>
                                <option value="3">
                                  For Warranty
                                </option>

                              </select>

                            </Box>

                            <Box>
                              <label>Note</label>
                              <textarea
                                className="form-control form-control-lg"
                                rows={4}
                                placeholder="Description"
                                value={value.reason}
                                onChange={(e) =>
                                  handleTracking(
                                    index,
                                    "reason",
                                    e.target.value
                                  )
                                }
                              ></textarea>

                              <label>Status</label>
                              <select value={value.status_id} onChange={(e) =>
                                handleTracking(
                                  index,
                                  "status_id",
                                  e.target.value
                                )
                              } className="form-control form-control-lg">
                                <option value="0">
                                  Pending
                                </option>
                                <option value="1">
                                  Completed
                                </option>
                              </select>
                            </Box>
                            {IsAllowed(["1562"]) && (
                              <Box p={1} display="grid" className="return-button-update">

                                <button
                                  className="btn btn-success btn-sm"
                                  onClick={() =>
                                    updateReturnRequest(value)
                                  }
                                >
                                  Update
                                </button>

                              </Box>
                            )}


                          </>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </LoadingOverlay>

                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingReturns;
