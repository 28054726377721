import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "../../Utils/Axios";
import Box from "@mui/material/Box";
import { IMAGE_URL, MAIN_URL } from "../../Constants/constants";
import IsAllowed from "../../Utils/IsAllowed";

function OrderDetails({ orderItems, status_id }) {
  const [loading, setLoading] = useState(false);
  const [orderDetail, setOrderDeatail] = useState([]);
  useEffect(() => {}, []);

  useEffect(() => {
    setOrderDeatail([]);
    setOrderDeatail((item) => [...item, ...orderItems]);
  }, [orderItems]);

  const handleItems = (index, dataType, value) => {
    const newState = orderDetail.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setOrderDeatail(newState);
  };

  const updateItemData = () => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("order_items", JSON.stringify(orderDetail));
    axios(`Orders/update_item_details`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (!res.data.error) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div class="container-fluid">
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <div class="card shadow mb-4">
          <div class="card-body"></div>
          <div class="card-body">
            <div className="table-responsive">
              <table className="table table-bordered  ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>SKU</th>
                    <th>Image</th>
                    <th>Unit Price</th>
                    <th>Qty</th>
                    <th>Total</th>
                    <th>Item status</th>
                  </tr>
                </thead>

                <tbody>
                  {orderDetail &&
                    orderDetail.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <h5>
                              {value.name}{" "}

                              {value.is_gift > 0 ? (
                                <a
                                  target="_blank"
                                  href={
                                    "https://uae-en.shopkees.com/shpcpanel/orders/print_gift_message/" +
                                    value.id
                                  }
                                >
                                  {" "}
                                  &nbsp; &nbsp; &nbsp;{" "}
                                  <i className="fa fa-gift gift_size"></i>
                                </a>
                              ) : (
                                ""
                              )}{" "}
                            </h5>
                            <h7>
                              {"seller : " + value.display_name}
                            </h7>
                            <h7>
                              {value.is_gift > 0 ? (
                                <span>Gift message: {value.gift_message}</span>
                              ) : (
                                ""
                              )}{" "}
                              &nbsp; &nbsp; &nbsp;{" "}
                              {value.is_gift > 0 && value.gift_message != "" ? (
                                <span>
                                  <a
                                    target="_blank"
                                    href={
                                      "https://uae-en.shopkees.com/shpcpanel/orders/print_gift_message/" +
                                      value.id
                                    }
                                  >
                                    <i className="fa fa-print"></i>
                                  </a>
                                </span>
                              ) : (
                                ""
                              )}
                            </h7>

                            {value.at_hub_status == 2 ? (
                              <div>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="initial"
                                  flexWrap="wrap"
                                  bgcolor="Background.paper"
                                >
                                  <Box p={1} display="grid">
                                    <label>Sl number </label>
                                    <input
                                      className="form-control"
                                      placeholder="Sl Number"
                                      onChange={(e) =>
                                        handleItems(
                                          index,
                                          "serial_no",
                                          e.target.value
                                        )
                                      }
                                      value={value.serial_no}
                                      type="text"
                                    ></input>
                                  </Box>
                                  <Box p={1} display="grid">
                                    <label>HS number </label>
                                    <input
                                      className="form-control"
                                      placeholder="HS Number"
                                      value={value.hscode}
                                      onChange={(e) =>
                                        handleItems(
                                          index,
                                          "hscode",
                                          e.target.value
                                        )
                                      }
                                      type="text"
                                    ></input>
                                  </Box>
                                  {IsAllowed(["1514"]) && (
                                  <Box p={1} display="grid">
                                    <button
                                      className="btn  btn-success btn-sm"
                                      onClick={updateItemData}
                                    >
                                      Update Items Data
                                    </button>
                                  </Box>
                                  )}
                                </Box>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td> {value.sku}</td>
                          <td>
                            <img
                              alt=""
                              src={IMAGE_URL + value.item_img_url}
                            ></img>
                          </td>

                          <td>
                            {value.special_price == 0
                              ? value.price
                              : value.special_price}
                          </td>
                          <td>{value.qty}</td>
                          <td>{value.subtotal}</td>
                          <td>
                            {value.at_hub_status == 2 ? (
                              <button className="btn btn-success btn-sm">
                                <i class="fa fa-check"></i>
                              </button>
                            ) : (
                              ""
                            )}
                            <br></br>
                            <br></br>

                            {value.msds_file == "" ||
                            value.msds_file == null ? (
                              ""
                            ) : (
                              <a
                                href={
                                  MAIN_URL + "uploads/msda/" + value.msds_file
                                }
                                className="btn btn-success btn-sm"
                                target="__blank"
                              >
                                <i class="fa fa-print"></i> MSDS
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default OrderDetails;
