import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
//import DataTable from "react-data-table-component";
import axios from "../../../Utils/Axios";
//import ReactPaginate from "react-paginate";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { useHistory } from "react-router-dom";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
//import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { IMAGE_URL, BASE_URL } from "../../../Constants/constants";
import OrderDetails from "../OrderDetails";
import fileDownload from "js-file-download";
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAlert } from "react-alert";
import IsAllowed from "../../../Utils/IsAllowed";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  PresetDateRangePicker_panel: {
    padding: "0 22px 11px 22px",
  },

  PresetDateRangePicker_button: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid #138496 `,
    color: "#138496",
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
  },

  PresetDateRangePicker_button__selected: {
    color: "white",
    background: "blue",
  },
}));

function ShippedOrders(props) {
  const classes = useStyles();
  const history = useHistory();
  const today = moment();
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState();
  const [per_page, setPer_page] = useState(10);
  const [isDate, setIsDate] = useState(props.isDate);
  const [loading, setLoading] = useState(false);
  const [shippingType, setShippingType] = useState([]);
  const [paymentType, setPaymentType] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState(
    props.keyword && props.keyword ? props.keyword : ""
  );
  const [shippingCarrier, setShippingCarrier] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [page, setPage] = useState(1);
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [cityName, setCityName] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [zones, setZones] = useState([]);
  const [zoneId, setZoneId] = useState(0);
  const alert = useAlert();
  const [expanded, setExpanded] = React.useState(false);
  const [storeLocation, setStoreLocation] = useState(0);
  const [orderType, setOrderType] = useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    orders_list();
  }, [
    page,
    search,
    per_page,
    endDate,
    startDate,
    shippingCarrier,
    paymentMethod,
    countryId,
    stateId,
    zoneId,
    cityName,
    storeLocation,
    orderType,
  ]);
  useEffect(() => {
    get_shipping_type();
    get_payment_type();
    getCountries();
  }, []);
  const viewDetails = (id) => {
    history.push({
      pathname: "/order-details",
      state: { id: id },
    });
  };

  const yesterday = moment().add(-1, "day");
  const presets = [
    {
      text: "Today",
      start: today,
      end: today,
    },
    {
      text: "Yesterday",
      start: yesterday,
      end: yesterday,
    },
    {
      text: "This Week",
      end: today,
      start: moment().add(-1, "week"),
    },
    {
      text: "This Month",
      end: today,
      start: moment().add(-1, "month"),
    },
    {
      text: "This Year",
      end: today,
      start: moment().add(-1, "year"),
    },
  ];

  const onDatesChange = (start, end) => {
    setEndDate(end);
    setStartDate(start);
  };

  function renderDatePresets() {
    return (
      <div className={classes.PresetDateRangePicker_panel}>
        {presets.map(({ text, start, end }) => {
          //const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);
          return (
            <button
              key={text}
              className={classes.PresetDateRangePicker_button}
              type="button"
              onClick={() => onDatesChange(start, end)}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  }
  const orders_list = () => {
    setLoading(true);
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    axios(
      `Orders/order_list?order_status=shipped&page=${page}&shipping_carrier=${shippingCarrier}&payment_type=${paymentMethod}&per_page=${per_page}&search=${search}&start_date=${start}&end_date=${end}&country_id=${countryId}&state_id=${stateId}&zone_id=${zoneId}&city_name=${cityName}&location_id=${storeLocation}&order_type=${orderType}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
            setOrders(res.data.data);
            setTotal(res.data.total);
            props.setShippedcount(res.data.total_count);
          } else {
            //console.log(res.data.data);
            setLoading(false);
            setOrders(res.data.data);
            setTotal(res.data.total);
            props.setShippedcount(res.data.total_count);
            //setPer_page(10);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const export_data = () => {
    setLoading(true);
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    axios(
      `Orders/export_data?order_status=shipped&shipping_carrier=${shippingCarrier}&payment_type=${paymentMethod}&start_date=${start}&end_date=${end}&country_id=${countryId}&state_id=${stateId}&zone_id=${zoneId}&city_name=${cityName}&location_id=${storeLocation}&order_type=${orderType}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        setLoading(false);
        fileDownload(res.data, "orders.csv");
      })
      .catch((err) => console.log(err));
  };

  const get_shipping_type = () => {
    setLoading(true);

    axios(`Orders/get_shipping_types`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setShippingType(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const get_payment_type = () => {
    setLoading(true);

    axios(`Dashboard/get_payment_method`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setPaymentType(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCities = (value) => {
    setLoading(true);
    setZoneId(value);
    setCityName("");
    axios(`Orders/get_cities?state_id=${stateId}&zone_id=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setCities(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getZones = (value) => {
    setLoading(true);
    setStateId(value);
    setZoneId(0);
    setCityName("");
    axios(`Orders/get_zones?state_id=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setZones(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getStates = (value) => {
    setLoading(true);
    setCountryId(value);
    setStateId(0);
    setZoneId(0);
    setCityName("");
    axios(`Orders/get_states?country_id=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setStates(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCountries = () => {
    setLoading(true);

    axios(`Orders/get_countries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setCountries(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">Shipped Orders</h1>

        <div class="card shadow mb-4">
          <div class="card-body">
            <div className="row order-row">
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search...."
                />
              </div>
              <div className="col-md-4">
                <DateRangePicker
                  presets={presets}
                  renderCalendarInfo={renderDatePresets}
                  showClearDates={true}
                  startDate={startDate}
                  startDateId="s_id"
                  endDate={endDate}
                  endDateId="e_id"
                  isOutsideRange={(day) => moment().diff(day) < 0}
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="YYYY-MM-DD"
                />
              </div>
              <div className="col-md-5 d-flex align-items-center">
                <select
                  className="form-control"
                  onChange={(e) => setShippingCarrier(e.target.value)}
                >
                  <option value="0">Select Shipping Carrier </option>
                  {shippingType &&
                    shippingType.map((val, index) => {
                      return (
                        <option key={index} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                </select>
                <input
                  type="button"
                  onClick={export_data}
                  className="btn btn-success m-2"
                  value="Export"
                />
                <a
                  target="_blank"
                  href={
                    BASE_URL +
                    "Orders/print_awb_number?order_status=shipped&shipping_carrier=" +
                    shippingCarrier +
                    "&payment_type=" +
                    paymentMethod +
                    "&orde_type=" +
                    orderType +
                    "&location_id=" +
                    storeLocation +
                    "&start_date=" +
                    moment(startDate).format("YYYY-MM-DD").toString() +
                    "&end_date=" +
                    moment(endDate).format("YYYY-MM-DD").toString()
                  }
                >
                  <input
                    type="button"
                    className="btn btn-info"
                    value="Export AWB"
                  />
                </a>
              </div>
              {/* <div className="col-md-1">
                <input
                  type="button"
                  onClick={export_data}
                  className="btn btn-success"
                  value="Export"
                />
              </div>
              <div className="col-md-1">
                <a target="_blank" href={BASE_URL+'Orders/print_awb_number?order_status=shipped&shipping_carrier='+shippingCarrier+'&start_date='+moment(startDate).format("YYYY-MM-DD").toString()+'&end_date='+moment(endDate).format("YYYY-MM-DD").toString()}><input
                  type="button"
                  className="btn btn-info"
                  value="Export AWB"
                /></a>
              </div> */}
            </div>
            <div className="row order-row">
              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <option value="0">Select Payment Type </option>
                  {paymentType &&
                    paymentType.map((val, index) => {
                      return (
                        <option key={index} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => getStates(e.target.value)}
                >
                  <option value="0">Select Country </option>
                  {countries &&
                    countries.map((val, index) => {
                      return (
                        <option key={index} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => getZones(e.target.value)}
                >
                  <option value="0">Select State </option>
                  {states &&
                    states.map((val, index) => {
                      return (
                        <option key={index} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => getCities(e.target.value)}
                >
                  <option value="0">Select Zone </option>
                  {zones &&
                    zones.map((val, index) => {
                      return (
                        <option key={index} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="row order-row">
              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => setCityName(e.target.value)}
                >
                  <option value="0">Select City </option>
                  {cities &&
                    cities.map((val, index) => {
                      return (
                        <option key={index} value={val.name}>
                          {val.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => setStoreLocation(e.target.value)}
                >
                  <option value="0">Select Store </option>
                  <option value="1">Bur Dubai </option>
                  <option value="2">Deira </option>
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => setOrderType(e.target.value)}
                >
                  <option value="0">Select Order Type </option>
                  <option value="1">Normal Order </option>
                  <option value="2">Store Pickup </option>
                  <option value="3">Email Delivery </option>
                </select>
              </div>
            </div>
            <div style={{ marginTop: 50 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="..."
                >
                  {orders.map((value, index) => {
                    return (
                      <Accordion
                        expanded={expanded === "panel" + index}
                        onChange={handleChange("panel" + index)}
                      >
                        <AccordionSummary
                          //expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Paper className={classes.paper}>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              flexWrap="wrap"
                              m={1}
                              //marginBottom="40px"
                              p={1}
                              bgcolor="Background.paper"
                            >
                              {/* <Box p={1}>
          <Checkbox />
        </Box> */}
                              <Box p={1} maxWidth="200px" minWidth="200px">
                                {/* <Link to="/PostJobDetails"> */}
                                <Typography
                                  variant="h6"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      value.order_no
                                    );
                                    alert.info(" order number copied");
                                  }}
                                  gutterBottom
                                >
                                  {value.order_no}
                                </Typography>
                                <Typography
                                  variant="overline"
                                  color="primary"
                                  gutterBottom
                                >
                                  {value.customer}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color="blue"
                                  gutterBottom
                                >
                                  {value.mobile_no}
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>
                                  {value.order_type == 1 ? (
                                    ""
                                  ) : value.order_type == 3 ? (
                                    <label
                                      className="label label-success"
                                      style={{
                                        backgroundColor: "#48a832",
                                        color: "#fff",
                                        borderRadius: "4px",
                                        padding: "4px 7px",
                                        marginRight: "5px",
                                      }}
                                    >
                                      <i className="fas fa-envelope"> Email</i>
                                    </label>
                                  ) : (
                                    <label
                                      className="label label-success"
                                      style={{
                                        backgroundColor: "#124251",
                                        color: "#fff",
                                        borderRadius: "4px",
                                        padding: "4px 7px",
                                        marginRight: "5px",
                                      }}
                                    >
                                      <i className="fas fa-store">Store</i>
                                    </label>
                                  )}
                                </Typography>
                              </Box>
                              <Box p={1} color="primary" display="grid">
                                <Typography
                                  variant="subtitle2"
                                  color="primary"
                                  gutterBottom
                                >
                                  {value.total}
                                </Typography>
                              </Box>

                              <Box p={1} display="grid">
                                <Typography
                                  variant="subtitle1"
                                  color="tomato"
                                  gutterBottom
                                >
                                  {value.country}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color="magenta"
                                  gutterBottom
                                >
                                  {value.state}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color="yellowgreen"
                                  gutterBottom
                                >
                                  {value.city}
                                </Typography>
                              </Box>

                              <Box p={1} display="grid">
                                <Typography
                                  variant="subtitle2"
                                  color="primary"
                                  gutterBottom
                                >
                                  {value.date_ordered}
                                </Typography>
                              </Box>

                              {/* <Button></Button> */}
                            </Box>
                          </Paper>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <OrderDetails
                              orderItems={value.order_items}
                              status_id={value.status_id}
                            ></OrderDetails>
                          </>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </LoadingOverlay>

                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShippedOrders;
