import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import IsAllowed from "../../Utils/IsAllowed";

//import IsAllowed from "../Utils/IsAllowed";

function SideBar() {
  function toggle() {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $(".sidebar .collapse").collapse("hide");
    }
  }

  return (
    <ul
      class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion "
      id="accordionSidebar"
    >
      <Link
        class="sidebar-brand d-flex align-items-center justify-content-center"
        to="/"
      >
        <div class="sidebar-brand-icon ">
          <img src="/logo.svg" height="150px" width="150px"></img>
        </div>
      </Link>

      <hr class="sidebar-divider my-0" />

      <li class="nav-item active">
        <Link class="nav-link" to="/">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Dashboard</span>
        </Link>
      </li>

      <hr class="sidebar-divider" />

      {(IsAllowed(["1510"]) || IsAllowed(["1550"])) && (
        <li class="nav-item">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <i class="fas fa-fw fa-shopping-cart"></i>
            <span>Orders</span>
          </a>
          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              {IsAllowed(["1510"]) && (
                <Link class="collapse-item" to="/orders">
                  Orders
                </Link>
              )}
              {IsAllowed(["1550"]) && (
                <Link class="collapse-item" to="/orderItems">
                  Order Items
                </Link>
              )}
            </div>
          </div>
        </li>
      )}

      {/* <li class="nav-item">
        <a
          class="nav-link collapsed"
          data-toggle="collapse"
          data-target="#collapseThree"
          aria-expanded="true"
          aria-controls="collapseThree"
        >
          <i class="fas fa-fw fa-users"></i>
          <span>Vendors</span>
        </a>
        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordionSidebar"
        >
          <div class=" py-2 collapse-inner rounded">
            <Link class="collapse-item" to="/vendors">
              Vendors
            </Link>
          </div>
        </div>
      </li> */}

      {IsAllowed(["1560"]) && (
        <li class="nav-item">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i class="fa fa-undo"></i>
            <span>Returns</span>
          </a>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              {IsAllowed(["1560"]) && (
                <Link class="collapse-item" to="/returns">
                  Returns
                </Link>
              )}
            </div>
          </div>
        </li>
      )}
      {IsAllowed(["152"]) && (
        <li class="nav-item">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseThree"
            aria-expanded="true"
            aria-controls="collapseThree"
          >
            <i class="fas fa-fw fa-th"></i>
            <span>Products</span>
          </a>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              <Link class="collapse-item" to="/products">
                Products
              </Link>
            </div>
          </div>
        </li>
      )}
      {IsAllowed(["153"]) && (
        <li class="nav-item">
          <Link class="collapse-item" to="/Scan" className="nav-link collapsed">
            <i class="fas fa-fw fa-truck"></i>
            <span>Scan Shipment</span>
          </Link>
        </li>
      )}
      {IsAllowed(["1515"]) && (
        <li class="nav-item">
          <Link class="collapse-item" to="/store-orders" className="nav-link collapsed">
          <i class="fa fa-solid fa-store"></i>
            <span>Store Orders</span>
          </Link>
        </li>
      )}

      <div class="text-center d-none d-md-inline">
        <button
          class="rounded-circle border-0"
          onClick={toggle}
          id="sidebarToggle"
        ></button>
      </div>
    </ul>
  );
}

export default SideBar;
