import React, { useContext, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import IsAllowed from "./Utils/IsAllowed";
import axios from "./Utils/Axios";
import { AuthContext } from "./Context/AuthContext";


import Header from "./Components/Templates/Header";
import Orders from "./Components/Orders/Orders";
import SearchOrders from "./Components/Orders/SearchOrders";
import OrderItems from "./Components/Orders/OrderItems";
import SideBar from "./Components/Templates/SideBar";
import Footer from "./Components/Templates/Footer";
import Dashboard from "./Components/Dashboard/Dashboard";
import Login from "./Components/Login/Login";
import Profile from "./Components/Login/Profile";
import CodOrders from "./Components/Orders/CodOrders";
import ForgotPassword from "./Components/Login/ForgotPassword";
import ResetPassword from "./Components/Login/ResetPassword";
import Vendors from "./Components/Vendors/Vendors";
import Products from "./Components/Products/Products";
import Notification from "./Components/Notifications/Notification";
import ScanShipment from "./Components/Orders/ScanShipment";
import Returns from "./Components/Returns/Returns";



function App() {
  const { role, setRole, setPermissions } = useContext(AuthContext);

  useEffect(() => {
    if (!role) {
      axios(`Users/get_user_role_permission`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (!res.data.status) {
          } else {
            setRole(res.data.data.role);
            setPermissions(res.data.data.permissions);
          }
        })
        .catch((err) => console.log(err));
    }
  });
  return (
    <div id="wrapper">
      <Switch>
        <LoginSideBar />
      </Switch>
      <Switch>
        <LoginHeader />
      </Switch>

      <div id="content-wrapper">
        <div id="content">
          <Switch>
            {IsAllowed(["1510"]) && (
              <ProtectedRoute path="/orders" exact component={Orders} />
            )}
            {IsAllowed(["1550"]) && (
              <ProtectedRoute path="/orderItems" exact component={OrderItems} />
            )}
            {IsAllowed(["1560"]) && (
              <ProtectedRoute path="/returns" exact component={Returns} />
            )}
            <ProtectedRoute path="/profile" exact component={Profile} />
            {IsAllowed(["1515"]) && (
            <ProtectedRoute path="/store-orders" exact component={CodOrders} />
            )}

            <ProtectedRoute path="/vendors" exact component={Vendors} />
            {IsAllowed(["152"]) && (
              <ProtectedRoute path="/products" exact component={Products} />
            )}

            <ProtectedRoute
              path="/notification"
              exact
              component={Notification}
            />

            {IsAllowed(["153"]) && (
              <ProtectedRoute path="/scan" exact component={ScanShipment} />
            )}
            <Route path="/login" exact component={Login} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/SearchOrders" component={SearchOrders} exact />
            <Route
              path="/reset-password/:key1/:key2"
              exact
              component={ResetPassword}
            />
            <ProtectedRoute path="/" exact component={Dashboard} />
          </Switch>
        </div>
      </div>
      <Switch>
        <LoginFooter />
      </Switch>
    </div>
  );
}

const ProtectedRoute = ({ component: Component, path }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        return localStorage.getItem("data") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              refresh: true,
              state: {
                prevLocation: path,
                error: "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};
const LoginHeader = () => {
  return localStorage.getItem("data") ? <Header rand={Math.random()} /> : "";
};
const LoginSideBar = () => {
  return localStorage.getItem("data") ? <SideBar /> : "";
};
const LoginFooter = () => {
  return localStorage.getItem("data") ? <Footer /> : "";
};

export default App;
