import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import LoadingOverlay from "react-loading-overlay";
// import Table from '@material-ui/core/Table';
// import TableHead from '@material-ui/core/TableHead';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableFooter from '@material-ui/core/TableFooter';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { Container } from "@mui/material";
import axios from "../../Utils/Axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { useAlert } from "react-alert";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});
function Vendors() {
  const classes = useStyles2();
  const alert = useAlert();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [vendors, setVendors] = useState([]);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [per_page, setPer_page] = useState(10);
  const [search, setSearch] = useState("");

  useEffect(() => {
    vendors_list();
  }, [page, search, per_page]);
  const addVendor = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("name", name);
    formdata.append("id", id);
    axios(`Vendor/add_vendor`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.error(res.data.message);
        } else {
          alert.show(res.data.message);
          vendors_list();
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const editData = (value) => {
    setName(value.name);
    setId(value.id);
  };

  const vendors_list = () => {
    setLoading(true);
    axios(
      `Vendor/vendor_list?page=${page}&per_page=${per_page}&search=${search}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          //alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
            setVendors(res.data.data);
            setTotal(res.data.total);
          } else {
            //console.log(res.data.data);
            setLoading(false);
            setVendors(res.data.data);
            setTotal(res.data.total);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    // <Container>
    // <TableContainer component={Paper}>
    //   <Table className={classes.table} aria-label="custom pagination table">
    //   <TableHead>
    //       <TableRow>
    //         <TableCell component="th" align="center">No</TableCell>
    //         <TableCell  component="th" align="center">Name</TableCell>
    //         <TableCell  component="th" align="center">Date</TableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //     {vendors.map((value, index) => {
    //                 return (
    //         <TableRow key={index}>
    //           <TableCell style={{ width: 160 }} align="center" >
    //             {value.id}
    //           </TableCell>
    //           <TableCell style={{ width: 160 }} align="center" >
    //             {value.name}
    //           </TableCell>
    //           <TableCell style={{ width: 160 }} align="center" >
    //           {value.date_ordered}
    //           </TableCell>
    //         </TableRow>
    //                 )})}

    //     </TableBody>
    //     <TableFooter>
    //       <TableRow>
    //         <TablePagination
    //           rowsPerPageOptions={[10, 25, 50 ]}
    //           colSpan={3}
    //           count={total}
    //           rowsPerPage={rowsPerPage}
    //           page={page}
    //           SelectProps={{
    //             inputProps: { 'aria-label': 'rows per page' },
    //             native: true,
    //           }}
    //           onPageChange={handleChangePage}
    //           onRowsPerPageChange={handleChangeRowsPerPage}
    //           ActionsComponent={TablePaginationActions}
    //         />
    //       </TableRow>
    //     </TableFooter>
    //   </Table>
    // </TableContainer>
    // </Container>

    <div>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">Vendors</h1>

        <div class="card shadow mb-4">
          <div class="card-body">
            <div className="row">
              <div className="col-md-10">
                <input
                  type="text"
                  placeholder="Vendor Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  className="form-control"
                ></input>
              </div>
              <div className="col-md-2">
                <button
                  className="btn btn-primary "
                  onClick={addVendor}
                >
                  {id == '' ? 'Add' : 'Update'}
                </button>
                {id != '' ? (
                <button
                  className="btn btn-danger "
                  onClick={() => {setId("");setName("");}}
                >
                 <i className="fa fa-times" ></i>
                </button>) : ''}
              </div>
            </div><br/>
            <div className="row">
              <div className="col-md-12">
              <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search...."
                />
              </div>
            </div>
            <div style={{ marginTop: 30 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="Loading ..."
                >
                  <table className="table table-bordered  ">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Created Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {vendors.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{value.name}</td>
                            <td>{value.created_at}</td>
                            <td>
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Edit"
                                onClick={() => {
                                  editData(value);
                                }}
                              ></input>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </LoadingOverlay>

                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vendors;
