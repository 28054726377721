import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "../../../Utils/Axios";

import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { useHistory } from "react-router-dom";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import fileDownload from "js-file-download";

//import { IMAGE_URL } from "../../Constants/constants";
import OrderDetails from "../OrderDetails";
import IsAllowed from "../../../Utils/IsAllowed";

//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  PresetDateRangePicker_panel: {
    padding: "0 22px 11px 22px",
  },

  PresetDateRangePicker_button: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid #138496 `,
    color: "#138496",
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
  },

  PresetDateRangePicker_button__selected: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid #138496 `,
    color: "#138496",
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
    color: "white",
    background: "#138496",
  },
}));

function BookedOrders(props) {
  const classes = useStyles();
  const history = useHistory();
  const today = moment();
  const [orders, setOrders] = useState([]);
  const [shippingType, setShippingType] = useState([]);

  const [total, setTotal] = useState();

  const [per_page, setPer_page] = useState(10);
  const [loading, setLoading] = useState(false);
  const [isDate, setIsDate] = useState(props.isDate);
  const [startDate, setStartDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState(
    props.keyword && props.keyword ? props.keyword : ""
  );
  const [page, setPage] = useState(1);
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [cityName, setCityName] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [zones, setZones] = useState([]);
  const [zoneId, setZoneId] = useState(0);
  const [orderType, setOrderType] = useState(0);

  const alert = useAlert();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    orders_list();
  }, [
    page,
    search,
    per_page,
    endDate,
    startDate,
    countryId,
    stateId,
    cityName,
    zoneId,
    orderType,
  ]);
  useEffect(() => {
    get_shipping_type();
    getCountries();
  }, []);

  const viewDetails = (id) => {
    history.push({
      pathname: "/order-details",
      state: { id: id },
    });
  };

  //const today = moment();
  const yesterday = moment().add(-1, "day");
  const presets = [
    {
      text: "Today",
      start: today,
      end: today,
    },
    {
      text: "Yesterday",
      start: yesterday,
      end: yesterday,
    },
    {
      text: "This Week",
      start: moment().add(-1, "week"),
      end: today,
    },
    {
      text: "This Month",
      start: moment().add(-1, "month"),
      end: today,
    },
    {
      text: "This Year",
      start: moment().add(-1, "year"),
      end: today,
    },
  ];

  const onDatesChange = (start, end) => {
    setEndDate(end);
    setStartDate(start);
  };

  const getTrackingNumber = (
    order_id,
    description,
    shipping_carrier_id,
    provider_name
  ) => {
    setLoading(true);
    axios(
      `Orders/order_booking?order_id=${order_id}&type=1&description=${description}&shipping_carrier_id=${shipping_carrier_id}&provider_name=${provider_name}&order_type=${orderType}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          orders_list();
        }
      })
      .catch((err) => console.log(err));
  };
  const updateOrderStatus = (orderData) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("order_id", orderData.id);
    formdata.append("tracking_no", orderData.tracking_no);
    formdata.append("shipping_carrier_id", orderData.shipping_carrier_id);
    formdata.append("licence_key", orderData.licence_key);
    formdata.append("admin_invoice_no", orderData.admin_invoice_no);
    formdata.append("status_id", 10);
    formdata.append("delivery_type", orderData.order_type);
    axios(`Orders/order_status_update_api`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          alert.success(res.data.message);
          orders_list();
        }
      })
      .catch((err) => console.log(err));
  };
  function isSameDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    // Compare least significant, most likely to change units first
    // Moment's isSame clones moment inputs and is a tad slow
    return (
      a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
    );
  }

  function renderDatePresets() {
    return (
      <div className={classes.PresetDateRangePicker_panel}>
        {presets.map(({ text, start, end }) => {
          const isSelected =
            isSameDay(start, startDate) && isSameDay(end, endDate);
          return (
            <button
              key={text}
              className={
                isSelected
                  ? classes.PresetDateRangePicker_button__selected
                  : classes.PresetDateRangePicker_button
              }
              type="button"
              onClick={() => onDatesChange(start, end)}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  }

  const handleTracking = (index, dataType, value) => {
    const newState = orders.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setOrders(newState);
  };
  const orders_list = () => {
    setLoading(true);
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    axios(
      `Orders/order_list?order_status=booked&page=${page}&per_page=${per_page}&search=${search}&country_id=${countryId}&state_id=${stateId}&zone_id=${zoneId}&city_name=${cityName}&start_date=${start}&end_date=${end}&order_type=${orderType}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
            setOrders(res.data.data);
            setTotal(res.data.total);
            props.setBookedcount(res.data.total_count);
          } else {
            //console.log(res.data.data);
            setLoading(false);
            setOrders(res.data.data);
            setTotal(res.data.total);
            props.setBookedcount(res.data.total_count);
            //setPer_page(10);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const export_data = () => {
    setLoading(true);
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    axios(
      `Orders/export_data?order_status=booked&start_date=${start}&end_date=${end}&country_id=${countryId}&state_id=${stateId}&zone_id=${zoneId}&city_name=${cityName}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        setLoading(false);
        fileDownload(res.data, "orders.csv");
      })
      .catch((err) => console.log(err));
  };
  const get_shipping_type = () => {
    setLoading(true);

    axios(`Orders/get_shipping_types`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setShippingType(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCities = (value) => {
    setLoading(true);
    setZoneId(value);
    setCityName("");
    axios(`Orders/get_cities?state_id=${stateId}&zone_id=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setCities(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getZones = (value) => {
    setLoading(true);
    setStateId(value);
    setZoneId(0);
    setCityName("");
    axios(`Orders/get_zones?state_id=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setZones(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getStates = (value) => {
    setLoading(true);
    setCountryId(value);
    setStateId(0);
    setZoneId(0);
    setCityName("");
    axios(`Orders/get_states?country_id=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setStates(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCountries = () => {
    setLoading(true);

    axios(`Orders/get_countries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setCountries(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">Pending Orders</h1>

        <div class="card shadow mb-4">
          <div class="card-body">
            <div className="row order-row">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control  form-control-lg "
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search...."
                />
              </div>

              <div className="col-md-5">
                <DateRangePicker
                  presets={presets}
                  renderCalendarInfo={renderDatePresets}
                  showClearDates={true}
                  startDate={startDate}
                  startDateId="s_id"
                  endDate={endDate}
                  endDateId="e_id"
                  isOutsideRange={(day) => moment().diff(day) < 0}
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="YYYY-MM-DD"
                />
              </div>
              <div className="col-md-2">
                <input
                  type="button"
                  onClick={export_data}
                  className="btn btn-success"
                  value="Export"
                />
              </div>
            </div>
            <div className="row order-row">
              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => getStates(e.target.value)}
                >
                  <option value="0">Select Country </option>
                  {countries &&
                    countries.map((val, index) => {
                      return (
                        <option key={index} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => getZones(e.target.value)}
                >
                  <option value="0">Select State </option>
                  {states &&
                    states.map((val, index) => {
                      return (
                        <option key={index} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => getCities(e.target.value)}
                >
                  <option value="0">Select Zone </option>
                  {zones &&
                    zones.map((val, index) => {
                      return (
                        <option key={index} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => setCityName(e.target.value)}
                >
                  <option value="0">Select City </option>
                  {cities &&
                    cities.map((val, index) => {
                      return (
                        <option key={index} value={val.name}>
                          {val.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="row order-row">
              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => setOrderType(e.target.value)}
                >
                  <option value="0">Select Order Type </option>
                  <option value="1">Normal Order </option>
                  <option value="2">Store Pickup </option>
                  <option value="3">Email Delivery </option>
                </select>
              </div>
            </div>
            <div style={{ marginTop: 50 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="..."
                >
                  {orders.map((value, index) => {
                    return (
                      <Accordion
                        expanded={expanded === "panel" + index}
                        onChange={handleChange("panel" + index)}
                      >
                        <AccordionSummary
                          //expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Paper className={classes.paper}>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              flexWrap="wrap"
                              m={1}
                              //marginBottom="40px"
                              p={1}
                              bgcolor="Background.paper"
                            >
                              {/* <Box p={1}>
          <Checkbox />
        </Box> */}
                              <Box p={1} maxWidth="200px" minWidth="200px">
                                {/* <Link to="/PostJobDetails"> */}
                                <Typography
                                  variant="h6"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      value.order_no
                                    );
                                    alert.info(" order number copied");
                                  }}
                                  gutterBottom
                                >
                                  {value.order_no}
                                </Typography>
                                <Typography
                                  variant="overline"
                                  color="primary"
                                  gutterBottom
                                >
                                  {value.customer}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color="blue"
                                  gutterBottom
                                >
                                  {value.mobile_no}
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>
                                  {value.order_type == 1 ? (
                                    ""
                                  ) : value.order_type == 3 ? (
                                    <label
                                      className="label label-success"
                                      style={{
                                        backgroundColor: "#48a832",
                                        color: "#fff",
                                        borderRadius: "4px",
                                        padding: "4px 7px",
                                        marginRight: "5px",
                                      }}
                                    >
                                      <i className="fas fa-envelope"> Email</i>
                                    </label>
                                  ) : (
                                    <label
                                      className="label label-success"
                                      style={{
                                        backgroundColor: "#124251",
                                        color: "#fff",
                                        borderRadius: "4px",
                                        padding: "4px 7px",
                                        marginRight: "5px",
                                      }}
                                    >
                                      <i className="fas fa-store"> Store {value.extra_fodel}</i>
                                    </label>
                                  )}
                                </Typography>
                              </Box>
                              <Box p={1} color="primary" display="grid">
                                <Typography
                                  variant="subtitle2"
                                  color="primary"
                                  gutterBottom
                                >
                                  {value.total}
                                </Typography>
                              </Box>

                              <Box p={1} display="grid">
                                <Typography
                                  variant="subtitle1"
                                  color="tomato"
                                  gutterBottom
                                >
                                  {value.country}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color="magenta"
                                  gutterBottom
                                >
                                  {value.state}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color="yellowgreen"
                                  gutterBottom
                                >
                                  {value.city}
                                </Typography>
                              </Box>

                              <Box p={1} display="grid">
                                <Typography
                                  variant="subtitle2"
                                  color="primary"
                                  gutterBottom
                                >
                                  {value.date_ordered}
                                </Typography>
                              </Box>
                              <Box p={1} display="grid">
                                <Box p={1}>
                                  <a
                                    target="_blank"
                                    href={
                                      "https://uae-en.shopkees.com/shpcpanel/orders/print_delivery_note/" +
                                      value.id +
                                      "/2"
                                    }
                                  >
                                    <button className="btn btn-warning btn-sm">
                                      Slip
                                    </button>
                                  </a>
                                </Box>
                                <Box p={1}>
                                  <a
                                    target="_blank"
                                    href={
                                      "https://uae-en.shopkees.com/shpcpanel/orders/print_packing_slip/" +
                                      value.id +
                                      "/2"
                                    }
                                  >
                                    <button className="btn btn-secondary btn-sm">
                                      Packing Slip
                                    </button>
                                  </a>
                                </Box>
                                <Box p={1}>
                                  <a
                                    target="_blank"
                                    href={
                                      "https://uae-en.shopkees.com/shpcpanel/orders/print_delivery_note/" +
                                      value.id +
                                      "/2"
                                    }
                                  >
                                    <button className="btn btn-info btn-sm">
                                      SAR Slip
                                    </button>
                                  </a>
                                </Box>
                              </Box>
                              <Box p={1} display="grid">
                                <Box p={1}>
                                  <a
                                    target="_blank"
                                    href={
                                      "https://uae-en.shopkees.com/shpcpanel/orders/admin_invoice/" +
                                      value.id +
                                      "/2"
                                    }
                                  >
                                    <button className="btn btn-success btn-sm">
                                      Admin Invoice
                                    </button>
                                  </a>
                                </Box>
                                <Box p={1}>
                                  <a
                                    target="_blank"
                                    href={
                                      "https://uae-en.shopkees.com/shpcpanel/orders/download_invoice/" +
                                      value.id +
                                      "/2"
                                    }
                                  >
                                    <button className="btn btn-success btn-sm">
                                      Invoice
                                    </button>
                                  </a>
                                </Box>
                              </Box>
                              {/* {value.status_id != 4 value.shipping_carrier_id == 1 && 
                              (value.tracking_no != 0 || value.tracking_no != "") ? (
                                <Box p={1} display="grid">
                                  <Box p={1}>
                                    <a
                                      target="_blank"
                                      href={
                                        "https://uae-en.shopkees.com/shpcpanel/airway_bill/nasex/pdf/" +
                                        value.id +
                                        ".pdf"
                                      }
                                    >
                                      <button className="btn btn-warning btn-sm">
                                        Nasex{" "}
                                        <i
                                          class="fa fa-file-pdf-o"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </a>
                                  </Box>
                                  <Box p={1}>
                                    <a
                                      target="_blank"
                                      href={
                                        "https://uae-en.shopkees.com/shpcpanel/airway_bill/nasex/label/" +
                                        value.id +
                                        ".pdf"
                                      }
                                    >
                                      <button className="btn btn-info btn-sm">
                                        Nasex Label
                                      </button>
                                    </a>
                                  </Box>
                                </Box>
                              ) : (
                                ""
                              )} */}

                              <Box p={1} display="grid">
                                {value.delivery_button_status == 1 ? (
                                  <button className="btn btn-info btn-sm">
                                    <i className="fa fa-check"></i>
                                  </button>
                                ) : (
                                  ""
                                )}
                              </Box>
                              {/* <Button></Button> */}
                            </Box>
                          </Paper>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            {value.delivery_button_status == 1 ? (
                              <div class="container-fluid">
                                <div class="card shadow mb-4">
                                  <div class="card-body">
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      flexWrap="wrap"
                                      bgcolor="Background.paper"
                                    >
                                      {value.order_type != 3 ? (
                                        <>
                                          <Box>
                                            <label>Shipping Carriers</label>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                handleTracking(
                                                  index,
                                                  "shipping_carrier_id",
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option value="">
                                                Select One{" "}
                                              </option>
                                              {shippingType &&
                                                shippingType.map(
                                                  (val, index) => {
                                                    return (
                                                      <option
                                                        key={index}
                                                        selected={
                                                          value.shipping_carrier_id ==
                                                            0 &&
                                                          value.deliverable ==
                                                            1 &&
                                                          val.id == 16
                                                            ? "selected"
                                                            : value.shipping_carrier_id ==
                                                              val.id
                                                            ? "selected"
                                                            : ""
                                                        }
                                                        value={val.id}
                                                      >
                                                        {val.name}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                          </Box>

                                          <Box>
                                            <label>Product Description</label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder="Product Description"
                                              value={value.description}
                                              onChange={(e) =>
                                                handleTracking(
                                                  index,
                                                  "description",
                                                  e.target.value
                                                )
                                              }
                                            ></input>
                                          </Box>
                                          <Box>
                                            <label>AWB Number</label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder="AWB Number"
                                              onChange={(e) =>
                                                handleTracking(
                                                  index,
                                                  "tracking_no",
                                                  e.target.value
                                                )
                                              }
                                              value={value.tracking_no}
                                            ></input>
                                          </Box>
                                          <Box>
                                            <label>Admin Invoce</label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder="Admin Invoice"
                                              onChange={(e) =>
                                                handleTracking(
                                                  index,
                                                  "admin_invoice_no",
                                                  e.target.value
                                                )
                                              }
                                              value={value.admin_invoice_no}
                                            ></input>
                                          </Box>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <Box>
                                            <label>Licence Key</label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder="Licence Key"
                                              value={value.licence_key}
                                              onChange={(e) =>
                                                handleTracking(
                                                  index,
                                                  "licence_key",
                                                  e.target.value
                                                )
                                              }
                                            ></input>
                                          </Box>
                                        </>
                                      )}
                                    </Box>

                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="initial"
                                      flexWrap="wrap"
                                      bgcolor="Background.paper"
                                    >
                                      {IsAllowed(["1553"]) && (
                                        <Box p={1} display="grid">
                                          <button
                                            className="btn btn-success btn-sm"
                                            onClick={() =>
                                              getTrackingNumber(
                                                value.id,
                                                value.description,
                                                value.shipping_carrier_id,
                                                "nasex"
                                              )
                                            }
                                          >
                                            Get Tracking Number
                                          </button>
                                        </Box>
                                      )}
                                      {IsAllowed(["1553"]) && (
                                        <Box p={1} display="grid">
                                          <button
                                            className="btn btn-success btn-sm"
                                            onClick={() =>
                                              updateOrderStatus(value)
                                            }
                                          >
                                            Ready for Shipp
                                          </button>
                                        </Box>
                                      )}
                                    </Box>

                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="initial"
                                      flexWrap="wrap"
                                      bgcolor="Background.paper"
                                    >
                                      <label>Order Comment : </label>
                                      <span>{value.order_comment}</span>
                                    </Box>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            <OrderDetails
                              orderItems={value.order_items}
                              status_id={value.status_id}
                            ></OrderDetails>
                          </>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </LoadingOverlay>

                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookedOrders;
