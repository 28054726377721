import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import { Link} from "react-router-dom";
import "./Dashboard.css";
// import TextField from "@mui/material/TextField";
// import Box from "@mui/material/Box";
// import Autocomplete from "@mui/material/Autocomplete";

function Dashboard() {
 // const history = useHistory();
  const [dashboard, setDashboard] = useState({});
  //const [loading, setLoading] = useState(false);
  
  

  useEffect(() => {
    getDashboard();
  }, []);
  const getDashboard = () => {
    axios(`Dashboard/get_store_dashboard`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setDashboard(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

 

  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-4 col-md-4 mb-4">
          <h1>Welcome !!</h1>
        </div>

        
      </div>
      <div class="row">
        <div class="col-xl-4 col-md-4 mb-4">
          <div class="card card-1  h-100 py-2">
            <div class="card-body">
              <Link to={{ pathname: `/orders`, tab: 0 }}>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto icon-bg">
                    <i class="fa fa-cart-plus fa-2x "> </i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Pending Orders
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                      {dashboard && dashboard.pending}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-md-4 mb-4">
          <div class="card card-3  h-100 py-2">
            <div class="card-body">
              <Link to={{ pathname: `/orders`, tab: 1 }}>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto icon-bg">
                    <i class="fa fa-paper-plane fa-2x"></i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Ready To Ship Orders
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                      {dashboard && dashboard.ready_to_shipp}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 mb-4">
          <div class="card card-2  h-100 py-2">
            <div class="card-body">
              <Link to={{ pathname: `/orders`, tab: 2 }}>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto icon-bg">
                    <i class="fa fa-truck fa-2x"></i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Shipped Orders
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                      {dashboard && dashboard.shipped}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
