import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";

import axios from "../../Utils/Axios";


import PendingReturns from "./PendingReturns"
import CompletedReturns from "./CompletedReturns"

import "react-tabs/style/react-tabs.css";
import "./Returns.css";
import IsAllowed from "../../Utils/IsAllowed";

function Returns(props) {
    const alert = useAlert();
    const [pendingCount, setPendingCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');



    const getItemList = () => {
        setLoading(true);

        axios(
            `Orders/search_return_item?search=${search}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "x-api-key": JSON.parse(localStorage.getItem("data")),
                },
            }
        )
            .then((res) => {
                if (!res.data.status) {
                    setLoading(false);
                    alert.show(res.data.message);
                } else {
                    setLoading(false);
                    setItems(res.data.data)
                }
            })
            .catch((err) => console.log(err));
    };


    const requestReturn = (item_id) => {
        setLoading(true);

        axios(
            `Orders/request_return?item_id=${item_id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "x-api-key": JSON.parse(localStorage.getItem("data")),
                },
            }
        )
            .then((res) => {
                if (!res.data.status) {
                    setLoading(false);
                    alert.show(res.data.message);
                } else {
                    setLoading(false);
                    alert.show(res.data.message);
                }
            })
            .catch((err) => console.log(err));

    }


    return (
        <>
         {IsAllowed(["1561"]) && (
            <>
            <div className="container-fluid">
                <div className="row" style={{ alignItems: "center", justifyContent: "center", }}>
                    <div className="col-md-5">
                        <input type="text" placeholder="Search Order,Product or Serial number ....." value={search} onChange={(e) => setSearch(e.target.value)} class="form-control" ></input>
                    </div>
                    <div className="col-md-1">
                        <input type="button" className="btn btn-info" onClick={getItemList} value="Search"></input>
                    </div>
                </div>
            </div>
            <br></br>
            <br></br>
            {items.length > 0 &&
                <div className="container-fluid">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Serial No</th>
                                <th>Qty</th>
                                <th>Price</th>
                                <th>Order Id</th>
                                <th>Invoice Number</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {items.map((value, index) => {
                            return (
                                <tr key={index}>
                                    <td>{value.name}</td>
                                    <td>{value.serial_no}</td>
                                    <td>{value.qty}</td>
                                    <td>{value.special_price == 0 ? value.price : value.special_price}</td>
                                    <td>{value.order_no}</td>
                                    <td>{value.admin_invoice_no}</td>
                                    <td><button className="btn btn-success" onClick={() => requestReturn(value.item_id)}>Return</button></td>
                                </tr>
                            )
                        })}
                    </table>
                </div>

            }
            <br></br>
            </>
            )}

            <Tabs >
                <TabList>
                    <Tab>
                        Pending Returns
                        <span class="badge badge-danger ml-2 badge-counter">{pendingCount} </span>
                    </Tab>
                    <Tab>
                        Completed Returns
                        <span class="badge badge-success ml-2 badge-counter">{completedCount} </span>
                    </Tab>
                </TabList>

                <TabPanel>
                    <PendingReturns setPendingCount={setPendingCount}></PendingReturns>
                </TabPanel>
                <TabPanel>
                    <CompletedReturns setCompletedCount={setCompletedCount}></CompletedReturns>
                </TabPanel>
            </Tabs>
        </>
    );
}

export default Returns;
