import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from "react-router-dom";

import './sb-admin-2.min.css'
import './fonts/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-oldschool-dark'
import Context from "./Context/AuthContext"


import App from './App';
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '10px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}


ReactDOM.render(
  <React.StrictMode>
     <Context>
     <AlertProvider template={AlertTemplate} {...options}>
    <Router>
    <App />
    </Router>
    </AlertProvider>
    </Context>
  </React.StrictMode>,
  document.getElementById('root')
);