import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import PendingOrders from "./OrderTabs/PendingOrders";
import BookedOrders from "./OrderTabs/BookedOrders";
import ReadyforShippedOrders from "./OrderTabs/ReadyforShippedOrders";
import ShippedOrders from "./OrderTabs/ShippedOrders";
import IsAllowed from "../../Utils/IsAllowed";

import "react-tabs/style/react-tabs.css";
import "./Orders.css";

function Orders(props) {
  const [deliveredcount, setDeliveredcount] = useState(0);
  const [pendingcount, setPendingcount] = useState(0);
  const [bookedcount, setBookedcount] = useState(0);
  const [readyforShippedcount, setReadyforShippedcount] = useState(0);
  const [shippedcount, setShippedcount] = useState(0);
  const [tabIndex, setTabIndex] = useState( props && props.location && props.location.tab ? props.location.tab : 0);
  const [keyword, setKeyword] = useState( props && props.location && props.location.keyword ? props.location.keyword : '');
  const [isDate, setIsDate] = useState( props && props.location && props.location.isDate ? props.location.isDate : 0);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList>
        <Tab>
          Pending
          <span class="badge badge-danger ml-2 badge-counter">{pendingcount} </span>
        </Tab>
        <Tab>
          Booked
          <span class="badge badge-warning ml-2 badge-counter">{bookedcount} </span>
        </Tab>
        <Tab>
          Ready for Ship
          <span class="badge badge-primary ml-2 badge-counter">
            {readyforShippedcount}
          </span>
        </Tab>
        <Tab>
          Shipped
          <span class="badge badge-success ml-2  badge-counter">{shippedcount}</span>
        </Tab>
      </TabList>

      <TabPanel>
        <PendingOrders setPendingcount={setPendingcount} isDate={isDate} keyword={keyword}></PendingOrders>
      </TabPanel>
      <TabPanel>
        <BookedOrders setBookedcount={setBookedcount} isDate={isDate} keyword={keyword}></BookedOrders>
      </TabPanel>
      <TabPanel>
        <ReadyforShippedOrders
          setReadyforShippedcount={setReadyforShippedcount}  isDate={isDate} keyword={keyword}
        ></ReadyforShippedOrders>
      </TabPanel>
      <TabPanel>
        <ShippedOrders setShippedcount={setShippedcount}  isDate={isDate} keyword={keyword}></ShippedOrders>
      </TabPanel>
    </Tabs>
  );
}

export default Orders;
