import React, { useState, useEffect, useRef } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "../../Utils/Axios";
import Modal from "react-modal";
import moment from "moment";
//import { IMAGE_URL } from "../../Constants/constants";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import { useAlert } from "react-alert";
import Switch from "react-switch";
import { AlignHorizontalCenter } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import IsAllowed from "../../Utils/IsAllowed";

function OrderDetails({ itemDetail, orders_list, vendors }) {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [cost, setCost] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [itemStatus, setItemStatus] = useState([]);
  const [itemComments, setItemComments] = useState({});
  const [comment, setComment] = useState("");
  const [itemId, setItemId] = useState(0);

  //const [vendors, setVendors] = useState([]);
  const [selectVendor, setSelectVendor] = useState(0);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const updateItemStatus = (i, status, orderItemId, selectVendor) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("order_item_id", orderItemId);
    formdata.append("status", status);
    formdata.append("comment", comment);
    formdata.append("vendor_id", selectVendor);
    formdata.append("cost", cost);
    axios(`Orders/update_item_status`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === false) {
          closeStatusModal(i);
          orders_list();
          setLoading(false);
        } else {
          alert.error(res.data.message);
          //closeStatusModal(i);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const updateItemTimeExtend = (value, orderItemId) => {
    value && (value = moment(value).format("YYYY-MM-DD").toString());
    axios(
      `Orders/update_item_extended_date?order_item_id=${orderItemId}&extended_date=${value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error === false) {
          setLoading(false);
          //alert.show(res.data.message);
          orders_list();
        } else {
          setLoading(false);
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  const updateItemPriorityStatus = (e, orderItemId) => {
    setLoading(true);
    let status;
    e ? (status = 1) : (status = 0);
    axios(
      `Orders/update_item_priority?order_item_id=${orderItemId}&status=${status}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error === false) {
          setLoading(false);
          //alert.show(res.data.message);
          orders_list();
        } else {
          setLoading(false);
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const getItemStatus = (order_item_id) => {
    setLoading(true);
    setIsOpen(true);
    axios(`Orders/get_order_item_status?order_item_id=${order_item_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === false) {
          setItemStatus(res.data.data);
          setLoading(false);
        } else {
          setItemStatus(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const getItemMessages = (order_item_id) => {
    setIsOpen2(true);
    setItemId(order_item_id);

    axios(`Orders/get_order_item_comments?order_item_id=${order_item_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === false) {
          setItemComments(res.data.data);
        } else {
          setItemComments(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const addOrderComment = (orderItemId, comment) => {
    let formdata = new FormData();
    formdata.append("order_item_id", orderItemId);
    formdata.append("comment", comment);
    axios(`Orders/add_order_item_comment`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === false) {
          getItemMessages(orderItemId);
          setComment("");
        }
      })
      .catch((err) => console.log(err));
  };

  const customStyles = {
    content: {
      background: "linear-gradient(180deg,#00475d,#00a6d9)",
      top: "30%",
      left: "40%",
      width: "25%",
      right: "auto",
      bottom: "auto",
      marginRight: "0%",
      transform: "fade in",
    },
  };

  const customStyles2 = {
    content: {
      //background: "linear-gradient(180deg,#00475d,#00a6d9)",
      top: "20%",
      left: "25%",
      width: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "0%",
      transform: "fade in",
    },
  };

  function closeModal() {
    setIsOpen(false);
  }
  function closeStatusModal(index) {
    handleChange(index, "pop_up_status", false);
    // orders_list();
  }

  const handleChange = (index, dataType, value) => {
    const newState = itemData.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setItemData(newState);
  };
  const handleChange2 = (index, dataType, dataType2, value, value2) => {
    const newState = itemData.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value, [dataType2]: value2 };
      }
      return item;
    });
    setItemData(newState);
  };

  const openStatusModal = (index, e) => {
    handleChange2(
      index,
      "order_item_status",
      "pop_up_status",
      e.target.value,
      true
    );
    //  handleChange(index, "pop_up_status", true);
    console.log(itemData);
  };
  useEffect(() => {
    setItemData([]);
    setItemData((item) => [...item, ...itemDetail]);
  }, [itemDetail]);

  return (
    <div class="container-fluid">
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="view">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-bordered  ">
                <th>Status</th>
                <th>Comments</th>
                <th>Date</th>
                <tbody>
                  {itemStatus &&
                    itemStatus.map((row, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {row.item_status == 2
                              ? "In Stock"
                              : row.item_status == 1
                              ? "Ordered"
                              : "Pending"}
                          </td>
                          <td>{row.comment}</td>
                          <td>{row.created_at}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isOpen2}
        shouldCloseOnOverlayClick={true}
        onRequestClose={(e) => setIsOpen2(false)}
        style={customStyles2}
        contentLabel="Example Modal"
      >
        <div className="container">
          <div class="row order-noti">
            <h6>Order ID : {itemComments.order_no}</h6>
            <h5>Order Name : {itemComments.product_name}</h5>
            <h6> Customer : {itemComments.customer_name}</h6>
            <h6> Email : {itemComments.email}</h6>
            <h6> Mobile : {itemComments.mobile_no}</h6>
            <h6> Address : {itemComments.address}</h6>
          </div>

          <div class="row">
            <div
              style={{ overflow: "scroll", height: "400px", width: "100%" }}
              id="msg_container"
            >
              {itemComments &&
                itemComments.comments &&
                itemComments.comments.map((row, i) => {
                  return row.created_by == itemComments.user_id ? (
                    
                    <div class="row m-0" style={{ justifyContent: "end" }}>
                      <div class="to-msg">
                        <div class="send-details">
                          <p class="to-time">{row.created_at}</p>
                          <h6 class="to-name">{row.name}</h6>
                        </div>

                        <div class="to-chat">{row.comment}</div>
                      </div>
                    </div>
                  ) : (
                    
                    <div class="row m-0">
                      <div class="from-msg">
                        <div class="rec-details">
                          <h6 class="rec-name">{row.name}</h6>
                          <p class="msg-time">{row.created_at}</p>
                        </div>

                        <div class="rec-chat">{row.comment}</div>
                      </div>{" "}
                    </div>
                  );
                })}
            </div>
          </div>
          <div ref={messagesEndRef} class="row msg_send">
            <div class="col-md-10">
              <input
                class="form-control"
                name="message-to-send"
                onChange={(e) => setComment(e.target.value)}
                id="message-to-send"
                placeholder="Type your message"
                type="text"
                value={comment}
              ></input>
            </div>
            <div class="col-md-2">
              <button
                class="btn  btn-info"
                onClick={() => addOrderComment(itemId, comment)}
                id="msg_send_order"
              >
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <div class="card shadow mb-4">
          <div class="card-body">
            <div className="table-responsive">
              <table className="table table-bordered  ">
                <thead>
                  <tr>
                    <th>Order</th>
                    <th>Country</th>
                    <th>Seller name</th>
                    <th>Unit Price</th>
                    <th>Final Price</th>
                    <th>Qty</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                    <th>Priority</th>
                  </tr>
                </thead>
                <tbody>
                  {itemDetail &&
                    itemDetail.map((val, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <Link
                              to={{
                                pathname: `/orders`,
                                tab: val.tab_status,
                                isDate: 1,
                                keyword: val.order_no,
                              }}
                            >
                              <span>{val.order_no}</span>
                            </Link>
                          </td>
                          <td>{val.country_name}</td>
                          <td>{val.seller_name}</td>
                          <td>{val.price}</td>
                          <td>{val.price_final}</td>
                          <td>{val.qty}</td>
                          <td>{val.created_at}</td>
                          <td>
                            {IsAllowed(["1554"]) && (
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  updateItemStatus(
                                    i,
                                    e.target.value,
                                    val.order_items_id,
                                    selectVendor
                                  )
                                }
                              >
                                <option
                                  value="0"
                                  selected={
                                    val.order_item_status == 0 ? "selected" : ""
                                  }
                                >
                                  Pending
                                </option>
                                <option
                                  value="1"
                                  selected={
                                    val.order_item_status == 1 ? "selected" : ""
                                  }
                                >
                                  Ordered{" "}
                                </option>
                                <option
                                  value="2"
                                  selected={
                                    val.order_item_status == 2 ? "selected" : ""
                                  }
                                >
                                  In Stock{" "}
                                </option>
                              </select>
                            )}
                            {/* {itemData &&
                            itemData[i] &&
                            itemData[i].pop_up_status ? (
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <select
                                  className="form-control"
                                  onChange={(e) =>
                                    setSelectVendor(e.target.value)
                                  }
                                >
                                  <option value="0">Select Vendor</option>
                                  {vendors.map((value) => {
                                    return (
                                      <option
                                        selected={
                                          val.vendor_id == value.id
                                            ? "selected"
                                            : ""
                                        }
                                        value={value.id}
                                        key={value.id}
                                      >
                                        {value.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <input
                                  className="form-control"
                                  placeholder="Cost"
                                  //onChange={(e) => setComment(e.target.value)}
                                  onChange={(e) => setCost(e.target.value)}
                                ></input>
                                <input
                                  className="form-control"
                                  placeholder="Comments"
                                  //onChange={(e) => setComment(e.target.value)}
                                  onChange={(e) =>
                                    handleChange(i, "cost", e.target.value)
                                  }
                                ></input>

                                <button
                                  className="btn btn-sm btn-danger "
                                  onClick={() => closeStatusModal(i)}
                                >
                                  X
                                </button>
                                <button
                                  className="btn btn-sm btn-success "
                                  onClick={() =>
                                    updateItemStatus(
                                      i,
                                      itemData[i].pop_up_comment,
                                      itemData[i].order_item_status,
                                      val.order_items_id,
                                      selectVendor
                                    )
                                  }
                                >
                                  <i className="fa fa-check"></i>
                                </button>
                              </Box>
                            ) : (
                              ""
                            )} */}
                           
                              {val.store_name}
                          </td>
                          <td>
                            <span>
                              <button
                                className="btn btn-sm btn-info"
                                title="status history"
                                onClick={() =>
                                  getItemStatus(val.order_items_id)
                                }
                              >
                                <i className="fa fa-list"></i>
                              </button>
                            </span>
                            {IsAllowed(["1551"]) && (
                              <span>
                                <button
                                  className="btn btn-sm btn-success"
                                  title="order comments"
                                  onClick={() =>
                                    getItemMessages(val.order_items_id)
                                  }
                                >
                                  <i
                                    class="fa fa-envelope"
                                    aria-hidden="true"
                                  ></i>
                                  {val.commented != 0 && (
                                    <span class="badge badge-primary ml-2 badge-counter">
                                      {" "}
                                      {val.commented}
                                    </span>
                                  )}
                                </button>
                              </span>
                            )}
                          </td>
                          <td>
                            {IsAllowed(["1552"]) && (
                              <span>
                                <Switch
                                  onChange={(e) =>
                                    updateItemPriorityStatus(
                                      e,
                                      val.order_items_id
                                    )
                                  }
                                  checked={val.priority === "0" ? 0 : 1}
                                />
                              </span>
                            )}
                            <br></br>
                            {IsAllowed(["1553"]) && (
                              <>
                                <span>
                                  <label>Time Extend :</label>
                                </span>

                                <span>
                                  <DatePicker
                                    selected={
                                      val.extended_date
                                        ? new Date(val.extended_date)
                                        : ""
                                    }
                                    onChange={(e) =>
                                      updateItemTimeExtend(
                                        e,
                                        val.order_items_id
                                      )
                                    }
                                  />
                                </span>
                              </>
                            )}
                            <br></br>
                            {val.shipping_method == 2 ? (
                              <span>
                                <i
                                  class="fas fa-shipping-fast fa-lg"
                                  style={{ color: "green" }}
                                ></i>
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default OrderDetails;
