import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import LoadingOverlay from "react-loading-overlay";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import axios from "../../Utils/Axios";
import Pagination from "@mui/material/Pagination";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { useAlert } from "react-alert";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});
function Products() {
  const classes = useStyles2();
  const alert = useAlert();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [productName, setProductName] = useState("");
  const [productCost, setProductCost] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productModel, setProductModel] = useState("");
  const [products, setProducts] = useState([]);
  const [per_page, setPer_page] = useState(10);
  const [search, setSearch] = useState("");
  const [selectBrand, setSelectBrand] = useState(-1);
  const [selectCategory, setSelectCategory] = useState(0);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    products_list();
    getBrands();
    getCategories();
  }, [page, search, per_page]);

  const getBrands = () => {
    axios(`Dashboard/get_brands`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (res.data.error) {
          } else {
            setBrands(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCategories = () => {
    axios(`Dashboard/get_root_categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (res.data.error) {
          } else {
            setCategories(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const products_list = () => {
    setLoading(true);
    axios(
      `Products/product_list?page=${page}&per_page=${per_page}&search=${search}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          //alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
            setProducts(res.data.data);
            setTotal(res.data.total);
          } else {
            //console.log(res.data.data);
            setLoading(false);
            setProducts(res.data.data);
            setTotal(res.data.total);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const syncToCorbuz = (id,company) => {
    setLoading(true);
    axios(`Products/sync_to_corbuz_new?product_id=${id}&company=${company}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.error(res.data.message);
        } else {
          setLoading(false);
          alert.success(res.data.message);
          products_list();
        }
      })
      .catch((err) => console.log(err));
  };

  const addProducts = () => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("name", productName);
    formdata.append("model_number", [productModel]);
    formdata.append("root_category", selectCategory);
    formdata.append("brand_id", selectBrand);
    formdata.append("price", 0);
    axios(`Products/add_product_details`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.error(res.data.message);
        } else {
          setLoading(false);
          alert.success(res.data.message);
          products_list();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">Products</h1>
        <Accordion>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <button className="btn btn-sm btn-info float-right ">
              Add Product
            </button>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row row-order">
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  placeholder="Name"
                />
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  value={productModel}
                  onChange={(e) => setProductModel(e.target.value)}
                  placeholder="Model No"
                />
              </div>
              <div className="col-md-3">
                <select
                  className="form-control form-control-lg"
                  onChange={(e) => setSelectCategory(e.target.value)}
                >
                  <option value="0">Select Category</option>
                  {categories.map((value) => {
                    return (
                      <option value={value.id} key={value.id}>
                        {value.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-control form-control-lg"
                  onChange={(e) => setSelectBrand(e.target.value)}
                >
                  <option value="-1">Select Brands</option>
                  {brands.map((value) => {
                    return (
                      <option value={value.id} key={value.id}>
                        {value.name}
                      </option>
                    );
                  })}
                </select>
              </div>
             
              <div className="offset-11 col-md-1 ">
                <br></br>
                <button
                  onClick={() => {
                    addProducts();
                  }}
                  className="btn btn btn-info"
                >
                  Add
                </button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <div class="card shadow mb-4">
          <div class="card-body">
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search...."
                />
              </div>
            </div>
            <div style={{ marginTop: 30 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="Loading ..."
                >
                  <table className="table table-bordered  ">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>SKU</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {products.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{value.name}</td>
                            <td>{value.sku}</td>
                            <td>
                              <span style={{display:'flex',gap:'8px'}}>
                              <input
                                type="button"
                                className="btn btn-danger btn-sm"
                                value="Sync FTT"
                                onClick={() => {
                                  syncToCorbuz(value.id,'ftt');
                                }}
                              ></input>
                              <input
                                type="button"
                                className="btn btn-info btn-sm"
                                value="Sync Shopkees"
                                onClick={() => {
                                  syncToCorbuz(value.id,'shopkees');
                                }}
                              ></input>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </LoadingOverlay>

                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
