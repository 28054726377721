import React, { useState, useEffect } from 'react'
import { useAlert } from "react-alert";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "../../Utils/Axios";

function SearchOrders(props) {
    const search = props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const [orders, setOrders] = useState([]);
    const today = moment();

    const [total, setTotal] = useState();

    const [per_page, setPer_page] = useState(10);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(moment().add(-1, "week"));
    const [focusedInput, setFocusedInput] = useState(null);
    const [endDate, setEndDate] = useState(today);
    const [page, setPage] = useState(1);
    const alert = useAlert();
    const [keyword, setKeyword] = React.useState(
        params.get("keyword") ? params.get("keyword") : ""
    );

    useEffect(() => {
        orders_list();
    }, [page, keyword, per_page]);

    const orders_list = () => {
        setLoading(true);

        axios(
            `Orders/search_orders?page=${page}&per_page=${per_page}&search=${keyword}&delay=1`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "x-api-key": JSON.parse(localStorage.getItem("data")),
                },
            }
        )
            .then((res) => {
                if (!res.data.status) {
                    setLoading(false);
                    alert.show(res.data.message);
                } else {
                    if (res.data.data.length === 0) {
                        setLoading(false);
                        setOrders(res.data.data);
                        setTotal(res.data.total);

                    } else {
                        console.log(res.data.data);
                        setLoading(false);
                        setOrders(res.data.data);
                        setTotal(res.data.total);


                    }
                }
            })
            .catch((err) => console.log(err));
    };
    return (
        <div>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                m={1}
                marginBottom="40px"
                p={1}

            >
                <List className="search-list" sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {orders.map((value, index) => {
                        return (

                            <ListItem alignItems="flex-start" className="search-li">
                                    {value.type == 1 ?
                                    <ListItemText
                                        primary={value.order_no}

                                        secondary={
                                            <React.Fragment className="search-name">
                                                <Typography

                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                >
                                                    {value.customer}
                                                </Typography>
                                                <Link to={{ pathname: `/orders`, tab: value.tab_status, isDate: 1, keyword: keyword }} className="search-link">
                                                <Typography

                                                    component="span"
                                                    variant="body1"
                                                    className={"search-badge ml-2 " + value.button_color + " pull-right"}
                                                >
                                                    {' ' + value.order_status + ' Order.'}
                                                </Typography>
                                                </Link>
                                                {value.status_id == 4 ?
                                                    <Link to={{ pathname: `/orderItems`,  tab: 4, isDate: 1, keyword: keyword }} className="search-link">
                                                        <Typography

                                                            component="span"
                                                            variant="body1"
                                                            className={"search-badge ml-2 bg-secondary  pull-right"}
                                                            >
                                                                In Order Item
                                                        </Typography>
                                                    </Link> : ''}

                                                <Typography className="search-r-date"> {value.date_ordered} </Typography>

                                            </React.Fragment>
                                        }
                                    />
                                
                                    : <ListItemText
                                    primary={value.order_no}

                                    secondary={
                                        <React.Fragment className="search-name">
                                            <Typography

                                                component="span"
                                                variant="subtitle2"
                                                color="text.primary"
                                            >
                                                {value.product_name}
                                            </Typography>
                                            <Link to={{ pathname: `/orderItems`, tab: value.tab_status ,isDate: 1, keyword: keyword }} className="search-link">
                                            <Typography

                                                component="span"
                                                variant="body1"
                                                className={"search-badge ml-2 " + value.button_color + " pull-right"}
                                            >
                                                {' ' + value.order_item_status + ' Item.'}
                                            </Typography>
                                            </Link>
                                            

                                            <Typography className="search-r-date"> {value.date_ordered} </Typography>

                                        </React.Fragment>
                                    }
                                />}
                            </ListItem>
                            

                        )
                    })}
                    <Divider variant="inset" component="li" />
                </List>
            </Box>

            <div>
                <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                        <Pagination
                            color="secondary"
                            count={total}
                            page={page}
                            onChange={(event, value) => {
                                setPage(value);
                            }}
                        />
                    </Stack>
                    <Stack spacing={2}>
                        <Select
                            value={per_page}
                            onChange={(e) => setPer_page(e.target.value)}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </Stack>
                </Box>
            </div>
        </div>

    )
}

export default SearchOrders