import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import LoadingOverlay from "react-loading-overlay";
// import Table from '@material-ui/core/Table';
// import TableHead from '@material-ui/core/TableHead';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableFooter from '@material-ui/core/TableFooter';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import Modal from "react-modal";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { Container } from "@mui/material";
import axios from "../../Utils/Axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { useAlert } from "react-alert";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const customStyles2 = {
  content: {
    //background: "linear-gradient(180deg,#00475d,#00a6d9)",
    top: "20%",
    left: "25%",
    width: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "0%",
    transform: "fade in",
  },
};

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});
function Notification() {
  const classes = useStyles2();
  const alert = useAlert();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [comments, setComments] = useState([]);
  const [per_page, setPer_page] = useState(10);
  const [search, setSearch] = useState("");
  const [isOpen2, setIsOpen2] = useState(false);
  const [itemComments, setItemComments] = useState({});
  const [comment, setComment] = useState("");
  const [itemId, setItemId] = useState(0);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    notification_list();
  }, [page, search, per_page]);

  const notification_list = () => {
    setLoading(true);
    axios(
      `Orders/notification_list?page=${page}&per_page=${per_page}&search=${search}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          //alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
            setComments(res.data.data);
            setTotal(res.data.total);
          } else {
            //console.log(res.data.data);
            setLoading(false);
            setComments(res.data.data);
            setTotal(res.data.total);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getItemMessages = (order_item_id) => {
    setIsOpen2(true);
    setItemId(order_item_id);

    axios(`Orders/get_order_item_comments?order_item_id=${order_item_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === false) {
          setItemComments(res.data.data);
          scrollToBottom();
        } else {
          setItemComments(res.data.data);
          scrollToBottom();
        }
      })
      .catch((err) => console.log(err));
  };

  const addOrderComment = (orderItemId, comment) => {
    let formdata = new FormData();
    formdata.append("order_item_id", orderItemId);
    formdata.append("comment", comment);
    axios(`Orders/add_order_item_comment`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === false) {
          getItemMessages(orderItemId);
          setComment("");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">Notification</h1>

        <div class="card shadow mb-4">
          <div class="card-body">
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search...."
                />
              </div>
            </div>
            <div style={{ marginTop: 30 }}>
              <div className="table-responsive">
                <Modal
                  isOpen={isOpen2}
                  shouldCloseOnOverlayClick={true}
                  onRequestClose={(e) => setIsOpen2(false)}
                  style={customStyles2}
                  contentLabel="Example Modal"
                >
                  <div className="container">
                    <div class="row order-noti">
                      <h6>Order ID : {itemComments.order_no}</h6>
                      <h5>Order Name : {itemComments.product_name}</h5>
                      <h6> Customer : {itemComments.customer_name}</h6>
                      <h6> Email : {itemComments.email}</h6>
                      <h6> Mobile : {itemComments.mobile_no}</h6>
                      <h6> Address : {itemComments.address}</h6>
                    </div>
                    <div class="row">
                      <div
                        style={{
                          overflow: "scroll",
                          height: "400px",
                          width: "100%",
                        }}
                        id="msg_container"
                      >
                        {itemComments &&
                          itemComments.comments &&
                          itemComments.comments.map((row, i) => {
                            return row.created_by == itemComments.user_id ? (
                              <div
                                class="row m-0"
                                style={{ justifyContent: "end" }}
                              >
                                <div class="to-msg">
                                  <div class="send-details">
                                    <p class="to-time">{row.created_at}</p>
                                    <h6 class="to-name">{row.name}</h6>
                                  </div>

                                  <div class="to-chat">{row.comment}</div>
                                </div>
                              </div>
                            ) : (
                              <div class="row m-0">
                                <div class="from-msg">
                                  <div class="rec-details">
                                    <h6 class="rec-name">{row.name}</h6>
                                    <p class="msg-time">{row.created_at}</p>
                                  </div>

                                  <div class="rec-chat">{row.comment}</div>
                                </div>{" "}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div ref={messagesEndRef} class="row msg_send">
                      <div class="col-md-10">
                        <input
                          class="form-control"
                          name="message-to-send"
                          onChange={(e) => setComment(e.target.value)}
                          id="message-to-send"
                          placeholder="Type your message"
                          type="text"
                          value={comment}
                        ></input>
                      </div>
                      <div class="col-md-2">
                        <button
                          class="btn  btn-sm btn-info"
                          onClick={() => addOrderComment(itemId, comment)}
                          id="msg_send_order"
                        >
                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="Loading ..."
                >
                  <table className="table table-bordered  ">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Order Id</th>
                        <th>Product Name </th>
                        <th>Comments</th>
                        <th>Created By</th>
                        <th>Time</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {comments.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{value.order_no}</td>
                            <td>{value.product_name}</td>
                            <td>{value.comment}</td>
                            <td>{value.name}</td>
                            <td>{value.created_at}</td>

                            <td>
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Show"
                                onClick={() =>
                                  getItemMessages(value.order_item_id)
                                }
                              ></input>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </LoadingOverlay>

                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;
