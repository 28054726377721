import { useContext, useEffect } from "react";
import { AuthContext } from "../Context/AuthContext";
//import axios from "../Utils/axios";

function IsAllowed(rights) {
  const { role, permissions } = useContext(AuthContext);
  return rights.some((right) => permissions.includes(right));
}
export default IsAllowed;

//export const hasRole = (user, roles) =>
// roles.some(role => user.roles.includes(role));
